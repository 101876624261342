import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables, routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { PositionEmployee, PositionManager, PositionVerbose } from '../../models/positions.model';
import { PositionsService } from '../../services/positions.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HistoryDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { WorkLocationsService } from '@app/modules/work-locations/services/work-locations.service';

@Component({
    selector: 'app-position-editor',
    templateUrl: './position-editor.component.html',
    styleUrls: ['./position-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PositionEditorComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent;

    @Input() readOnly: boolean;
    @Input() readOnlyPositionId: string;

    @Output() formGeneratorComponentEmit: EventEmitter<FormGeneratorComponent> = new EventEmitter<FormGeneratorComponent>();

    public routes: typeof routes = routes;
    formId: string = 'frm_fAyLSe2p9XQkC4';
    changeReasonFormId: string = 'frm_cwTMKxheRUNZud';
    formData: any;
    getFormData: boolean = false;
    formValid: boolean = false;
    position: PositionVerbose;
    positionId: string;
    isLoading: boolean = true;
    loadingPositionEmployees: boolean;
    positionEmployees: PositionEmployee[];
    loadingPositionManagers: boolean;
    positionManagers: PositionManager[];
    effectiveDate = new Date();
    isWorklocationInactive: boolean = false;
    public columns: any[] = [
        {field: 'name', title: 'name', tableId: "tfi_PosName"},
        {field: 'costCenters', title: 'cost Centers', type: 'costCenter', tableId: "tfi_PosAssistant"},
        {field: 'assistant', title: 'assistant', tableId: "tfi_PosAssistant"},
        {field: 'department',  subField: "text", title: 'department', tableId: "tfi_PosDepartment"},
        {field: 'division',  subField: "text", title: 'division', tableId: "tfi_PosDivision"},
        {field: 'employeeCategory',  subField: "text", title: 'employee Category', tableId: "tfi_PosEmployeeCategory"},
        {field: 'employmentGroup',  subField: "text", title: 'employment Group', tableId: "tfi_PosEmploymentGroup"},
        {field: 'employmentType',  subField: "text", title: 'employment Type', tableId: "tfi_PosEmploymentType"},
        {field: 'functionalJobTitle',  subField: "text", title: 'functional Job Title', tableId: "tfi_PosFunctionalJobTitle"},
        {field: 'jobBand',  subField: "text", title: 'job Band', tableId: "tfi_PosJobBand"},
        {field: 'jobFamily',  subField: "text", title: 'job Family', tableId: "tfi_PosJobFamily"},
        {field: 'jobGroup',  subField: "text", title: 'job Group', tableId: "tfi_PosJobGroup"},
        {field: 'jobStep',  subField: "text", title: 'job Step', tableId: "tfi_PosJobStep"},
        {field: 'jobType',  subField: "text", title: 'job Type', tableId: "tfi_PosJobType"},
        {field: 'marketPosition',  subField: "text", title: 'market Position', tableId: "tfi_PosMarketPosition"},
        {field: 'marketPositionTitle',  subField: "text", title: 'market Position Title', tableId: "tfi_PosMarketPositionTitle"},
        {field: 'marketView',  subField: "text", title: 'market View', tableId: "tfi_PosMarketView"},
        {field: 'projectTeam',  subField: "text", title: 'project Team', tableId: "tfi_PosProjectTeam"},
        {field: 'region',  subField: "text", title: 'region', tableId: "tfi_PosRegion"},
        {field: 'shiftCode',  subField: "text", title: 'shift Code', tableId: "tfi_PosShiftCode"},
        {field: 'subDivision',  subField: "text", title: 'sub Division', tableId: "tfi_PosSubDivision"},
        {field: 'unionCode',  subField: "text", title: 'union Code', tableId: "tfi_PosUnionCode"},
        {field: 'workLocation',  subField: "name", title: 'work Location', tableId: "tfi_PosWorkLocation"},
        {field: 'workRotation',  subField: "name", title: 'work Rotation', tableId: "tfi_PosWorkRotation"},
        {field: 'workRotationStartDate', title: 'work Rotation Start Date', type: 'date', tableId: "tfi_PosWorkRotationStartDate"},
        {field: 'organization',  subField: "name", title: 'organization', tableId: "tfi_PosOrganization"},
        {field: 'parentPosition',  subField: "name", title: 'parent Position', tableId: "tfi_PositionParent"},
        {field: 'numberOfSlots', title: 'number Of Slots', tableId: "tfi_PosNumberOfSlots"},
        {field: 'startDate', title: 'start Date', type: 'date'},
        {field: 'endDate', title: 'end Date', type: 'date'},
        {field: 'enforceSlotLimit', title: 'enforce Slot Limit', tableId: "tfi_PosEnforceSlotLimit"},
        {field: 'clientPositionId', title: 'client Position Id', tableId: "tfi_PosClientPositionId"},
        {field: 'fte', title: 'fte', tableId: "tfi_PosFte"},
        {field: 'udl1',  subField: "text", title: 'udl1', tableId: "tfi_PosUdl1"},
        {field: 'udl2',  subField: "text", title: 'udl2', tableId: "tfi_PosUdl2"},
        {field: 'udt1', title: 'udt1', tableId: "tfi_PosUdt1"},
        {field: 'udt2', title: 'udt2', tableId: "tfi_PosUdt2"},
        {field: 'udd1', title: 'udd1', type: 'date', tableId: "tfi_PosUdd1"},
        {field: 'udd2', title: 'udd2', type: 'date', tableId: "tfi_PosUdd2"},
        {field: 'udd3', title: 'udd3', type: 'date', tableId: "tfi_PosUdd3"},
        {field: 'udd4', title: 'udd4', type: 'date', tableId: "tfi_PosUdd4"},
        {field: 'udd5', title: 'udd5', type: 'date', tableId: "tfi_PosUdd5"},
        {field: 'udd6', title: 'udd6', type: 'date', tableId: "tfi_PosUdd6"},
        {field: 'udd7', title: 'udd7', type: 'date', tableId: "tfi_PosUdd7"},
      ];

    constructor(
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private positionsService: PositionsService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private workLocationsService: WorkLocationsService,
    ) {}

    ngOnInit(): void {
        if(this.readOnlyPositionId){
            this.positionId = this.readOnlyPositionId;
        }
        else {
            this.positionId = this.getIdInURL();
        }

        if(this.positionId !== null) {
            this.getPosition();
            this.getPositionEmployees();
            this.getPositionManagers();
        }
        else {
            this.isLoading = false;
        }
    }

    formDataEmitted(formData) {
        this.openChangeReasonDialog(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
        this.formGeneratorComponentEmit.emit(this.formGeneratorComponent);
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('positionId')
        );

        return IdInURL;
    }

    getPosition() {
        this.isLoading = true;

        let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

        this.positionsService.getPosition(this.positionId, asOf)
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(
            res => {
                this.position = res;
                this.createFormData();
                this.checkIfWorkLocationIsInactive();
            }
        );
    }

    getPositionEmployees() {
        this.loadingPositionEmployees = true;

        this.positionsService.getPositionEmployees(this.positionId)
        .pipe(
            finalize(() => this.loadingPositionEmployees = false)
        )
        .subscribe(
            res => {
                this.positionEmployees = res;
            }
        );
    }

    getPositionManagers() {
        this.loadingPositionManagers = true;

        this.positionsService.getPositionManagers(this.positionId)
        .pipe(
            finalize(() => this.loadingPositionManagers = false)
        )
        .subscribe(
            res => {
                this.positionManagers = res;
            }
        );
    }

    createFormData() {
        this.formData = {
            id: this.position ? this.position.id : null,
            clientPositionId: this.position ? this.position.clientPositionId : null,
            startDate: this.position ? this.position.startDate : null,
            endDate: this.position ? this.position.endDate : null,
            organization: this.position ? this.position.organization : null,
            assistant: this.position ? this.position.assistant : false,
            functionalJobTitle: this.position && this.position.functionalJobTitle ? this.position.functionalJobTitle.id : null,
            region: this.position && this.position.region ? this.position.region.id : null,
            division: this.position && this.position.division ? this.position.division.id : null,
            subDivision: this.position && this.position.subDivision ? this.position.subDivision.id : null,
            department: this.position && this.position.department ? this.position.department.id : null,
            workLocation: this.position ? this.position.workLocation?.id : null,
            workRotationStartDate: this.position ? this.position.workRotationStartDate : null,
            workRotation: this.position ? this.position.workRotation?.id : null,
            jobGroup: this.position && this.position.jobGroup ? this.position.jobGroup.id : null,
            jobFamily: this.position && this.position.jobFamily ? this.position.jobFamily.id : null,
            jobBand: this.position && this.position.jobBand ? this.position.jobBand.id : null,
            jobType: this.position && this.position.jobType ? this.position.jobType.id : null,
            jobStep: this.position && this.position.jobStep ? this.position.jobStep.id : null,
            projectTeam: this.position && this.position.projectTeam ? this.position.projectTeam.id : null,
            marketPositionTitle: this.position && this.position.marketPositionTitle ? this.position.marketPositionTitle.id : null,
            marketPosition: this.position && this.position.marketPosition ? this.position.marketPosition.id : null,
            marketView: this.position && this.position.marketView ? this.position.marketView.id : null,
            unionCode: this.position && this.position.unionCode ? this.position.unionCode.id : null,
            shiftCode: this.position && this.position.shiftCode ? this.position.shiftCode.id : null,
            employmentGroup: this.position && this.position.employmentGroup ? this.position.employmentGroup.id : null,
            employeeCategory: this.position && this.position.employeeCategory ? this.position.employeeCategory.id : null,
            employmentType: this.position && this.position.employmentType ? this.position.employmentType.id : null,
            enforceSlotLimit: this.position ? this.position.enforceSlotLimit : false,
            numberOfSlots: this.position ? this.position.numberOfSlots : 1,
            slotsAvailable: this.position ? this.position.slotsAvailable : null,
            slotsFilled: this.position ? this.position.slotsFilled : null,
            parentPosition: this.position?.parentPosition?.id ? this.position.parentPosition.id : null,
            name: this.position ? this.position.name : null,
            costCenters: this.position ? this.position.costCenters : null,
            fte: this.position ? this.position.fte : null,
            udl1: this.position ? this.position.udl1?.id : null,
            udl2: this.position ? this.position.udl2?.id : null,
            udt1: this.position ? this.position.udt1 : null,
            udt2: this.position ? this.position.udt2 : null,
            udd1: this.position ? this.position.udd1 : null,
            udd2: this.position ? this.position.udd2 : null,
            udd3: this.position ? this.position.udd3 : null,
            udd4: this.position ? this.position.udd4 : null,
            udd5: this.position ? this.position.udd5 : null,
            udd6: this.position ? this.position.udd6 : null,
            udd7: this.position ? this.position.udd7 : null,
        };
    }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          formId: this.changeReasonFormId,
        };

        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
              if (data) {
                  this.addChangeReasonsToFormData(formData, data);
              }
          }
        );
    }

    addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData: any) {
        this.overlayService.show();

        // Add asof and originalCreateOn values for future effective dating
        formData.asOf = moment(this.effectiveDate).format('YYYY-MM-DD');
        formData.originalCreatedOn = this.position?.version?.createdOn ? this.position?.version?.createdOn : null;

        // Adding the organization id to the form data for saving
        formData.organization = formData.organization?.id;
        if (formData.startDate) {formData.startDate = moment(formData.startDate).format().slice(0, 10);}
        if (formData.endDate) {formData.endDate = moment(formData.endDate).format().slice(0, 10);}
        if (this.position) {
            this.positionsService.putPosition(this.position.id, formData)
                .pipe(
                    finalize(() => {
                        this.overlayService.hide();
                        this.getPositionEmployees();
                        this.getPositionManagers();
                    })
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                    }
                );
        } else {
            this.positionsService.postPosition(formData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                    }
                );
        }
    }

    isActiveEmployee(endDate: string) {
        if(endDate === null) {
            return -1;
        }

        return moment().diff(endDate, 'days');
    }

    openHistoryDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: null,
            entityId: this.position.id,
            url: api_routes.POSITIONS + '/' + this.position.id,
            columns: this.columns,
            tableId: db_tables.Positions
        };

        const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    effectiveDateChange(event) {
        if(this.position){
            this.getPosition();
        }
    }

    checkIfWorkLocationIsInactive() {
        if(this.position?.workLocation?.id) {
            this.workLocationsService.getWorkLocation(this.position?.workLocation?.id)
            .subscribe(
                (res) => {

                    let workLocationDetails = res;

                    const date = moment(workLocationDetails.endDate, 'M/D/YYYY h:mm:ss A');
                    const now = moment(); // Current date and time

                    if (date.isBefore(now)) {
                        this.isWorklocationInactive = true;
                    }

                }
            );
        }
    }
}


<app-layout>

    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="BRA">
                {{ businessRuleId ? 'Edit' : 'Create'}}
                {{ 'BRA' | translate: {Default: "Business Rule Automation"} }}
            </h5>

            <div class="k-flex-grow"></div>

            <div class="buttons-container">
                <mat-icon
                    setColor
                    [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                }
            ]"
                    aria-hidden="false"
                    class="material-icons-outlined k-mr-3"
                    aria-label="add icon"
                    (click)="$event.stopPropagation(); openAccessLogsDialog()">
                    fingerprint
                </mat-icon>
                <mat-icon 
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]" 
                    aria-hidden="false" 
                    class="material-icons-outlined k-mr-3" 
                    aria-label="history icon"
                    (click)="$event.stopPropagation(); openHistoryDialog()"
                >history</mat-icon>
                <button
                    class="mat-raised-button"
                    (click)="close()">
                    {{ 'Close' | translate: {Default: "Close"} }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    setColor="primaryColour"
                    (click)="getFormData = !getFormData"
                    [disabled]="!form.valid || !formValid"
                    *ngIf="form"
                    class="k-ml-4">
                    {{ 'Save' | translate: {Default: "Save"} }}
                </button>
            </div>
        </mat-card-title>


        <mat-card-content>
            <ng-container *ngIf="!shouldShowForm; else loaded">
                <ngx-skeleton-loader
                    count="8"
                    [theme]="{
                              'height.px': 50
                          }"
                ></ngx-skeleton-loader>
            </ng-container>

            <ng-template #loaded>
                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>

                <ng-container [formGroup]="form">
                    <div class="form-section-title">
                        <h6 appLocalizationPopupDirective localizationCode="BRA-Trigger">{{ 'BRA-Trigger' | translate: {Default: "Trigger"} }}</h6>
                    </div>

                    <div class="help-alert">
                        <mat-icon class="material-icons-outlined help-icon">help_outline</mat-icon>
                        <p class="help-text" appLocalizationPopupDirective localizationCode="BRA-TriggerHelp">{{ 'BRA-TriggerHelp' | translate: {Default: "Placeholder Business Rule Automation Trigger Help"} }}</p>
                    </div>

                    <mat-form-field appLocalizationPopupDirective localizationCode="BRA-TriggerType" appearance="fill">
                        <mat-label>
                            {{ 'BRA-TriggerType' | translate: {Default: "Trigger Type"} }}
                        </mat-label>

                        <mat-select disableOptionCentering formControlName="triggerType">
                            <ng-container *ngFor="let triggerType of triggerTypes">
                                <mat-option [value]="triggerType.id">{{triggerType.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appLocalizationPopupDirective localizationCode="BRA-Form" appearance="fill">
                        <mat-label>{{ 'BRA-Form' | translate: {Default: "Form"} }}</mat-label>
                        <mat-select formControlName="tableId" disableOptionCentering [disabled]="!form.get('triggerType')?.value">
                            <ng-container *ngFor="let table of filteredTriggerTables">
                                <mat-option [value]="table.id">{{ table.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appLocalizationPopupDirective localizationCode="BRA-Field" appearance="fill">
                        <mat-label>{{ 'BRA-Field' | translate: {Default: "Field"} }}</mat-label>
                        <mat-select formControlName="fieldId" disableOptionCentering [disabled]="!form.get('tableId')?.value">
                            <ng-container *ngFor="let field of fields">
                                <mat-option [value]="field.id">{{ field.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appLocalizationPopupDirective localizationCode="BRA-Condition" appearance="fill">
                        <mat-label>{{ 'BRA-Condition' | translate: {Default: "Condition"} }}</mat-label>
                        <mat-select disableOptionCentering formControlName="conditionType">
                            <ng-container *ngFor="let conditionType of filteredConditionTypes">
                                <mat-option [value]="conditionType.id">{{conditionType.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field
                        *ngIf="form.get('conditionType')?.value != 'DateBefore' && form.get('conditionType')?.value != 'DateAfter'"
                        appLocalizationPopupDirective localizationCode="BRA-Value"
                        appearance="fill"
                    >
                        <mat-label>{{ 'BRA-Value' | translate: {Default: "Value"} }}</mat-label>
                        <input matInput formControlName="valueOne">
                    </mat-form-field>

                    <!-- Datepicker Input -->
                    <mat-form-field
                    *ngIf="form.get('conditionType')?.value == 'DateBefore' || form.get('conditionType')?.value == 'DateAfter'"
                    appLocalizationPopupDirective localizationCode="BRA-Value"
                    appearance="fill"
                    >
                        <mat-label>{{ 'BRA-Value' | translate: {Default: "Value"} }}</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="valueOne">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <div class="form-section-title">
                        <h6 appLocalizationPopupDirective localizationCode="BRA-Rules">{{ 'BRA-Rules' | translate: {Default: "Rules"} }}</h6>

                        <button appLocalizationPopupDirective localizationCode="BRA-AddRule" mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewRule()" [disabled]="rules.length >= 3">
                            <mat-icon class="material-icons-outlined">add</mat-icon>
                            {{ 'BRA-AddRule' | translate: {Default: "Add Rule"} }}
                        </button>
                    </div>

                    <div class="help-alert">
                        <mat-icon class="material-icons-outlined help-icon">help_outline</mat-icon>
                        <p class="help-text" appLocalizationPopupDirective localizationCode="BRA-RulesHelp">{{ 'BRA-RulesHelp' | translate: {Default: "Placeholder Business Rule Automation Rules Help"} }}</p>
                    </div>

                    <div class="form-section-contents properties">
                        <div class="section-row">
                            <ng-container formArrayName="rules">
                                <ng-container *ngFor="let propertyForm of rules.controls; let i = index">
                                    <div class="form-row">
                                        <ng-container>

                                            <business-rule-automation-rule [propertyForm]="propertyForm"
                                                                           [tables]="tables"
                                                                           [fields]="fieldsCache[propertyForm.value.tableId]"
                                                                           [conditionTypes]="conditionTypesCache[propertyForm.value.fieldId]"
                                                                           [index]="i"
                                                                           (deleteRequest)="deleteRule(i)"
                                            ></business-rule-automation-rule>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>


                    <div class="form-section-title">
                        <h6 appLocalizationPopupDirective localizationCode="BRA-Outcomes">{{ 'BRA-Outcomes' | translate: {Default: "Outcomes"} }}</h6>
                    </div>

                    <div class="help-alert">
                        <mat-icon class="material-icons-outlined help-icon">help_outline</mat-icon>
                        <p class="help-text" appLocalizationPopupDirective localizationCode="BRA-OutcomesHelp">{{ 'BRA-OutcomesHelp' | translate: {Default: "Placeholder Business Rule Automation Outcomes Help"} }}</p>
                    </div>


                    <mat-form-field appLocalizationPopupDirective localizationCode="BRA-SelectActionType" appearance="fill">
                        <mat-label>
                            {{ 'BRA-SelectActionType' | translate: {Default: "Select Action Type"} }}
                        </mat-label>

                        <mat-select disableOptionCentering formControlName="outcomeType" [disabled]="!form.valid"
                                    [matTooltip]="!form.valid ? 'Please fill out the form before adding an outcome' : ''"
                                    (selectionChange)="onOutcomeSelectionChange($event)"
                        >
                            <ng-container *ngFor="let outcome of outcomeTypes">
                                <mat-option [value]="outcome.id">{{outcome.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </ng-container>


                <ng-container *ngIf="businessRuleId != null">
                    <div class="notifications-container">
                        <app-business-rule-automation-notifications
                            [businessRuleId]="businessRuleId"
                            [notificationTypes]="notificationTypes"
                            [notificationRecipientTypes]="notificationRecipientTypes"
                        ></app-business-rule-automation-notifications>
                    </div>
                </ng-container>

            </ng-template>
        </mat-card-content>
    </mat-card>
</app-layout>

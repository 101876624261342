import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { db_tables, routes } from '@app/consts';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {
  @Input() section: string;
  public routes: typeof routes = routes;
  employeeId: string;

  navList = [];
  public db_tables = db_tables;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoading: boolean = true;
  // selectedComponent = this.translate.instant('PersonalDetails');
  searchText: string;

  constructor(
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private moduleConfigurationService: ModuleConfigurationService,
  ) {
    this.employeeId = this.getIdInURL();

    this.getTargetEmployeeTablePermissions();
  }

  ngOnInit(): void {}

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )

    return IdInURL;
  }

  getTargetEmployeeTablePermissions() {
    this.isLoading = true;

    this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
    .pipe(
      finalize(() => {
          this.buildNavList();
          this.navigateToFirstAllowedSection();
      })
    )
    .subscribe(
      res => {
        this.targetEmployeeTablePermissions = res;
      }
    );
  }

  buildNavList() {
    this.navList = [
      {
        text: this.translate.instant('PersonalDetails'),
        localizationCode: 'PersonalDetails',
        section: 'PersonalDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/PersonalDetails`,
        tableAccess: {
          tableId: db_tables.Talent,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('LeaveHistory'),
        localizationCode: 'LeaveHistory',
        section: 'LeaveHistory',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/LeaveHistory`,
        module: modules.TIME_OFF,
        tableAccess: {
          tableId: db_tables.ABSENCES,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('ToilHistory'),
        localizationCode: 'ToilHistory',
        section: 'ToilHistory',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ToilHistory`,
        module: modules.TOIL,
        tableAccess: {
          tableId: db_tables.TOIL,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Compensation'),
        localizationCode: 'Compensation',
        section: 'Compensation',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Compensation`,
        tableAccess: {
          tableId: db_tables.Compensation,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('OtherCompensation'),
        localizationCode: 'OtherCompensation',
        section: 'OtherCompensation',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/OtherCompensation`,
        tableAccess: {
          tableId: db_tables.OtherCompensations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('EmploymentDetails'),
        localizationCode: 'EmploymentDetails',
        section: 'EmploymentDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmploymentDetails`,
        tableAccess: {
          tableId: db_tables.EmploymentDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('EmergencyContacts'),
        localizationCode: 'EmergencyContacts',
        section: 'EmergencyContacts',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmergencyContacts`,
        tableAccess: {
          tableId: db_tables.EmergencyContacts,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('FamilyDependants'),
        localizationCode: 'FamilyDependants',
        section: 'FamilyDependants',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/FamilyDependants`,
        tableAccess: {
          tableId: db_tables.Family,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('MedicalTests'),
        localizationCode: 'MedicalTests',
        section: 'MedicalTests',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/MedicalTests`,
        tableAccess: {
          tableId: db_tables.MedicalTestings,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('VisaPermits'),
        localizationCode: 'VisaPermits',
        section: 'VisaPermits',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/VisaPermits`,
        tableAccess: {
          tableId: db_tables.VisaPermits,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Languages'),
        localizationCode: 'Languages',
        section: 'Languages',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Languages`,
        tableAccess: {
          tableId: db_tables.Languages,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Disciplines'),
        localizationCode: 'Disciplines',
        section: 'Disciplines',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Disciplines`,
        tableAccess: {
          tableId: db_tables.Disciplines,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('TrainingAndCertifications'),
        localizationCode: 'TrainingAndCertifications',
        section: 'TrainingAndCertifications',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/TrainingAndCertifications`,
        tableAccess: {
          tableId: db_tables.TrainingAndCertifications,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Education'),
        localizationCode: 'Education',
        section: 'Education',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Education`,
        tableAccess: {
          tableId: db_tables.Education,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('WorkExperiences'),
        localizationCode: 'WorkExperiences',
        section: 'WorkExperiences',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/WorkExperiences`,
        tableAccess: {
          tableId: db_tables.WorkExperiences,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('ProfessionalExpertises'),
        localizationCode: 'ProfessionalExpertises',
        section: 'ProfessionalExpertises',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ProfessionalExpertises`,
        tableAccess: {
          tableId: db_tables.ProfessionalExpertises,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Associations'),
        localizationCode: 'Associations',
        section: 'Associations',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Associations`,
        tableAccess: {
          tableId: db_tables.Associations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Documents'),
        localizationCode: 'Documents',
        section: 'Documents',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Documents`,
        tableAccess: {
          tableId: db_tables.DocumentLibrary,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('ProfileImage'),
        localizationCode: 'ProfileImage',
        section: 'ProfileImage',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ProfileImage`,
        tableAccess: {
          tableId: db_tables.ProfilePictures,
          permission: 'update',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Projects'),
        localizationCode: 'Projects',
        section: 'Projects',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Projects`,
        tableAccess: {
          tableId: db_tables.Projects,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('CompanyAssets'),
        localizationCode: 'CompanyAssets',
        section: 'CompanyAssets',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/CompanyAssets`,
        tableAccess: {
          tableId: db_tables.CompanyAssets,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Relocations'),
        localizationCode: 'Relocations',
        section: 'Relocations',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Relocations`,
        tableAccess: {
          tableId: db_tables.Relocations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('Grievances'),
        localizationCode: 'Grievances',
        section: 'Grievances',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Grievances`,
        tableAccess: {
          tableId: db_tables.Grievances,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('InjuryIllness'),
        localizationCode: 'InjuryIllness',
        section: 'InjuryIllness',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/InjuryIllness`,
        tableAccess: {
          tableId: db_tables.InjuryIllness,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('BankDetails'),
        localizationCode: 'BankDetails',
        section: 'BankDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/BankDetails`,
        tableAccess: {
          tableId: db_tables.BankDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('PayrollDetails'),
        localizationCode: 'PayrollDetails',
        section: 'PayrollDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/PayrollDetails`,
        tableAccess: {
          tableId: db_tables.PayrollDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('IntegrationDetails'),
        localizationCode: 'IntegrationDetails',
        section: 'IntegrationDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/IntegrationDetails`,
        tableAccess: {
          tableId: db_tables.IntegrationDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
    ];

    // this.navList.sort((a, b) => a.text.localeCompare(b.text));

    // If the client doesnt have access to a module remove it form the list
    this.navList = this.navList.filter(
      feature => {
        if(feature.module) {
          return this.moduleConfigurationService.hasModuleAccess(feature.module);
        }
        else {
          return true;
        }
      }
    )

    this.isLoading = false;

    setTimeout(() => {
      this.scrollToElement();
    }, 500);
  }

  scrollToElement() {
    const elements = document.querySelectorAll('.scrollTarget');

    elements[0]?.scrollIntoView();
  }

  getSectionInUrl(): string {
    let SectionInURL: string;

    this.route.paramMap.subscribe(
      params => SectionInURL = params.get("section")
    )

    return SectionInURL;
  }

  redirectTo(route: string) {
    // this.router.navigateByUrl(this.router.url.replace(this.section, route));
    this.router.navigate([`TalentTrack/Edit/${this.employeeId}/EmployeeDetails/${route}`]);
  }

  navigateToFirstAllowedSection() {
    let urlToNavigateTo = '';

    if(this.router.url === `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}`) {
      const personalDetails = this.navList.find(element => element.section === 'PersonalDetails');
      const hasPersonalDetailsAccess = personalDetails && this.tablePermissionsService.hasReadPermission(personalDetails.tableAccess.tableId, this.targetEmployeeTablePermissions);
  
      if (hasPersonalDetailsAccess) {
        urlToNavigateTo = personalDetails.link;
      } else {
        this.navList.every(element => {
          let hasAccess = this.tablePermissionsService.hasReadPermission(element.tableAccess.tableId, this.targetEmployeeTablePermissions);
  
          if (hasAccess) {
            urlToNavigateTo = element.link;
            return false;
          }
  
          return true;
        });
      }
    }

    if (urlToNavigateTo !== '') {
      this.router.navigate([urlToNavigateTo]);
    }
  }
}

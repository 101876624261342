<ng-container>

    <app-data-grid-comp
        [columns]="columns"
        [filterCategories]="filterCategories"
        [gridDataResult]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        hideActionButtons="true"
        category="Logs"
        [rowSelectionSettings]="{
            canSelectRows: false,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        [searchable]="false"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        showRefreshButton="true"
        (emitRefreshEvent)="getData()"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToIntegration($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>


</ng-container>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { TimeOff, TimeOffEmailTranslation } from '@app/modules/lookup/models/time-off.model';
import { TimeOffPolicy } from '@app/modules/lookup/models/time-off-policy.model';
import { TimeOffFirstAccrualType } from '@app/modules/lookup/models/time-off-first-accrual-type.model';
import { TimeOffStartAtUnit } from '@app/modules/lookup/models/time-off-start-at-unit.model';
import { TimeOffPolicySchedule } from '@app/modules/lookup/models/time-off-policy-schedule.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TimeOffListVariant } from '@app/modules/lookup/models/time-off-list-variant.model';
import { EnvironmentService } from "@app/core/services/environment.service";


@Injectable({
  providedIn: 'root'
})
export class TimeOffService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  async getAllTimeOffTypes(sortString?: string, filterString?: string): Promise<PagedData<TimeOffListVariant>> {
    let take = 100;
    let skip = 0;
    let timeOffTypes = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (timeOffTypes.total === null || skip < timeOffTypes.total) {
        const res = await this.getTimeOffTypes(skip, take.toString(), sortString, filterString).toPromise();

        if (res && res.data && res.data.length > 0) {
          timeOffTypes.data = timeOffTypes.data.concat(res.data);
            skip += take;
            timeOffTypes.total = res.total;
        } else {
            break;
        }
    }

    return timeOffTypes;
  }

  getTimeOffTypes(skip?: number, take?: string, sortString?: string, filterString?: string): Observable<PagedData<TimeOffListVariant>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : params = params.append('skip', "0");
    take ? params = params.append('take', take) : params = params.append('take', "50");
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(this.envService.env.apiUrl + 'Timeoff/Types', {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    });
  }

  getTimeOffTypeById(id: string): Observable<TimeOff> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/Types/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  postTimeOffType(timeOff: any): Observable<any> {
    return this.http.post<any>(this.envService.env.apiUrl + 'Timeoff/Types', timeOff, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateTimeOffType(timeOffTypeId: string, timeOff: any): Observable<any> {
    return this.http.put<any>(this.envService.env.apiUrl + `Timeoff/Types/${timeOffTypeId}`, timeOff, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffTypeIcon(typeOffTypeId: string): Observable<any> {
    return this.http.get(this.envService.env.apiUrl + `Timeoff/Types/${typeOffTypeId}/Icon`, {responseType: 'text'});
  }

  public postTimeOffTypeIcon(image: File, timeOffTypeId: string): Observable<Object> {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post(this.envService.env.apiUrl + 'Timeoff/Types/' + timeOffTypeId + '/Icon', formData);
  }

  deleteTimeOffTypeById(id: string): Observable<TimeOff> {
    return this.http.delete<any>(this.envService.env.apiUrl + `Timeoff/Types/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffPolicies(id: string): Observable<PagedData<TimeOffPolicy>> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/Types/${id}/Policies/`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffPolicyById(timeOffTypeId: string, policyId: string): Observable<TimeOffPolicy> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/Types/${timeOffTypeId}/Policies/${policyId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteTimeOffPolicyById(timeOffTypeId: string, policyId: string): Observable<TimeOff> {
    return this.http.delete<any>(this.envService.env.apiUrl + `Timeoff/Types/${timeOffTypeId}/Policies/${policyId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  createTimeOffPolicy(timeOffTypeId: string, timeOffPolicyData: any) {
    return this.http.post<any>(this.envService.env.apiUrl + `Timeoff/Types/${timeOffTypeId}/Policies`, timeOffPolicyData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateTimeOffPolicy(timeOffTypeId: string, timeOffPolicyId: string, timeOffPolicyData: any) {
    return this.http.put<any>(this.envService.env.apiUrl + `Timeoff/Types/${timeOffTypeId}/Policies/${timeOffPolicyId}`, timeOffPolicyData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  getTimeOffFirstAccrualTypes(): Observable<TimeOffFirstAccrualType[]> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/FirstAccrualTypes`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffStartAtUnits(): Observable<TimeOffStartAtUnit[]> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/StartAtUnits`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffAccrualIntervals(): Observable<TimeOffStartAtUnit[]> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/AccrualRateIntervals`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTimeOffAccruedAt(skip?: number, take?: number, filterString?: string, sortString?: string): Observable<TimeOffStartAtUnit[]> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : params = params.append('skip', "0");
    take ? params = params.append('take', take) : params = params.append('take', "50");
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/AccruedAtTypes`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    });
  }

  getTimeOffPolicySchedules(timeOffPolicyId: string): Observable<PagedData<TimeOffPolicySchedule>> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/Policies/${timeOffPolicyId}/Schedules`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteTimeOffPolicyScheduleById(timeOffPolicyId: string, scheduleId: string): Observable<TimeOff> {
    return this.http.delete<any>(this.envService.env.apiUrl + `Timeoff/Policies/${timeOffPolicyId}/Schedules/${scheduleId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  postTimeOffPolicySchedule(timeOffPolicyId: string, timeOffPolicyScheduleData: any) {
    return this.http.post<any>(this.envService.env.apiUrl + `Timeoff/Policies/${timeOffPolicyId}/Schedules`, timeOffPolicyScheduleData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  putTimeOffPolicySchedule(timeOffPolicyId: string, scheduleId: string, timeOffPolicyScheduleData: any) {
    return this.http.put<any>(this.envService.env.apiUrl + `Timeoff/Policies/${timeOffPolicyId}/Schedules/${scheduleId}`, timeOffPolicyScheduleData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getEmailTranslations(translationId: string): Observable<TimeOffEmailTranslation> {
    return this.http.get<any>(this.envService.env.apiUrl + `Timeoff/Emails/${translationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateEmailTranslation(translationId: string, translationData: TimeOffEmailTranslation) {
    return this.http.put<any>(this.envService.env.apiUrl + `Timeoff/Emails/${translationId}`, translationData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


}

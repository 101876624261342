import { Component, OnInit } from '@angular/core';
import { Position, PositionVerbose } from './models/positions.model';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { db_tables, features, routes } from '@app/consts';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin, Observable } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { PositionsService } from './services/positions.service';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { RowActionButtonInput, RowActionEventOutput } from '../talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { TableField } from '../security-setup/models/table-field.model';
import { SecuritySetupService } from '../security-setup/services/security-setup.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableFieldSecurity } from '@app/shared/models/employee.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { GridState } from '@app/shared/services/grid-state/grid-state.model';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent extends SecurityProtectedBase implements OnInit {
  public db_tables = db_tables;
  public routes: typeof routes = routes;
  public features: typeof features = features;
  public columns: any[] = [
    {field: 'name', title: 'Name', tableId: "tfi_PosName", dataType: 'String'},
    {field: 'employees', title: this.translate.instant('AssignedToPosition'), type: 'employees'},
    {field: 'department', subField: 'text', title: 'Department', tableId: "tfi_PosDepartment", dataType: "Lookup", lookupCode: 'DEPARTMENT'},
    {field: 'workRotation', subField: 'name', title: this.translate.instant('WorkRotation'), tableId: "tfi_PosWorkRotation", dataType: "SpecialLookup", lookupCode: 'WORK_ROTATION'},
    {field: 'employeeCategory', subField: 'text', title: 'Employee Category', tableId: "tfi_PosEmployeeCategory", dataType: "Lookup", lookupCode: 'EMPLOYEE_CATEGORY'},
    {field: 'employmentType', subField: 'text', title: 'Employment Type', tableId: "tfi_PosEmploymentType", dataType: "Lookup", lookupCode: 'EMPLOYMENT_TYPE'},
    {field: 'functionalJobTitle', subField: 'text', title: 'Functional Job Title', tableId: "tfi_PosFunctionalJobTitle", dataType: "Lookup", lookupCode: 'FUNCTIONAL_JOB_TITLE'},
  ];
  public sort: SortDescriptor[] = [];
  public bindingType: String = 'array';
  public view: Observable<GridDataResult>;
  public gridDataResult: GridDataResult;
  clearSelectedItems: boolean = false;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string = "name-asc";
  getPositionsRequest: any;
  fieldList: TableField[];
  sortingColumns: boolean;
  public searchFilterString: string;
  public searchValue: string;
  gridId: string = 'positions-list-data-grid'

  public filterCategories: any[] = [
    {field: 'name', title: 'Name', tableId: "tfi_PosName", dataType: 'String'},
    {field: 'department.id', title: 'Department', tableId: "tfi_PosDepartment", dataType: "Lookup", lookupCode: 'DEPARTMENT'},
    {field: 'workRotation.id', title: this.translate.instant('WorkRotation'), tableId: "tfi_PosWorkRotation", dataType: "SpecialLookup", lookupCode: 'WORK_ROTATION'},
    {field: 'employeeCategory.id', title: 'Employee Category', tableId: "tfi_PosEmployeeCategory", dataType: "Lookup", lookupCode: 'EMPLOYEE_CATEGORY'},
    {field: 'employmentType.id', title: 'Employment Type', tableId: "tfi_PosEmploymentType", dataType: "Lookup", lookupCode: 'EMPLOYMENT_TYPE'},
    {field: 'functionalJobTitle.id', title: 'Functional Job Title', tableId: "tfi_PosFunctionalJobTitle", dataType: "Lookup", lookupCode: 'FUNCTIONAL_JOB_TITLE'},

    {field: 'region.id', title: 'Region', tableId: "tfi_PosRegion", dataType: "Lookup", lookupCode: 'REGION',},
    {field: 'division.id', title: 'Division', tableId: "tfi_PosDivision" , dataType: "Lookup", lookupCode: 'DIVISION'},
    {field: 'subDivision.id', title: 'Sub Division', tableId: "tfi_PosSubDivision" , dataType: "Lookup", lookupCode: 'SUB_DIVISION'},
    {field: 'jobBand.id', title: 'Job Band', tableId: "tfi_PosJobBand" , dataType: "Lookup", lookupCode: 'JOB_BAND'},
    {field: 'jobStep.id', title: 'Job Step', tableId: "tfi_PosJobStep" , dataType: "Lookup", lookupCode: 'JOB_STEP'},
    {field: 'jobType.id', title: 'Job Type', tableId: "tfi_PosJobType" , dataType: "Lookup", lookupCode: 'JOB_TYPE'},
    {field: 'jobFamily.id', title: 'Job Family', tableId: "tfi_PosJobFamily" , dataType: "Lookup", lookupCode: 'JOB_FAMILY'},
    {field: 'jobGroup.id', title: 'Job Group', tableId: "tfi_PosJobGroup" , dataType: "Lookup", lookupCode: 'JOB_GROUP'},
    {field: 'projectTeam.id', title: 'Project Team', tableId: "tfi_PosProjectTeam" , dataType: "Lookup", lookupCode: 'PROJECT_TEAM'},
    {field: 'marketPositionTitle.id', title: 'Market Position Title', tableId: "tfi_PosMarketPositionTitle" , dataType: "Lookup", lookupCode: 'MARKET_POSITION_TITLE'},
    {field: 'marketPosition.id', title: 'Market Position', tableId: "tfi_PosMarketPosition" , dataType: "Lookup", lookupCode: 'MARKET_POSITION'},
    {field: 'marketView.id', title: 'Market View', tableId: "tfi_PosMarketView" , dataType: "Lookup", lookupCode: 'MARKET_VIEW'},
    {field: 'unionCode.id', title: 'Union Code', tableId: "tfi_PosUnionCode" , dataType: "Lookup", lookupCode: 'UNION_CODE'},
    {field: 'shiftCode.id', title: 'Shift Code', tableId: "tfi_PosShiftCode" , dataType: "Lookup", lookupCode: 'SHIFT_CODE'},
    {field: 'employmentGroup.id', title: 'Employment Group', tableId: "tfi_PosEmploymentGroup" , dataType: "Lookup", lookupCode: 'EMPLOYMENT_GROUP'},
    {field: 'workLocation.name', title: 'Work Location', tableId: "tfi_PosWorkLocation" , dataType: "SpecialLookup", lookupCode: 'WORK_LOCATION'},
    {field: 'organization.name', title: 'Organisation', tableId: "tfi_PosOrganization" , dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
    {field: 'parentPosition.name', title: 'Reports To', tableId: "tfi_PositionParent" , dataType: "String"},
    {field: 'clientPositionId', title: 'Client Position ID', tableId: "tfi_PosClientPositionId" , dataType: "String"},
    {field: 'startDate', title: 'Start Date', tableId: "tfi_StartDate" , dataType: "Date"},
    {field: 'endDate', title: 'End Date', tableId: "tfi_EndDate" , dataType: "Date"},
    {field: 'CostCenter.id', title: 'Cost Center', tableId: "tfi_CostCentre" , dataType: 'SpecialLookup', lookupCode: 'CostCenter'},
  ];
  sortableColumns: any[] = [
    { field: "name", sortValue: "name" },
    // { field: "employees", sortValue: ["Employee.FirstName", "Employee.LastName"]},
    { field: "department", sortValue: "department.text" },
    { field: "workRotation", sortValue: "workRotation.name" },
    { field: "employeeCategory", sortValue: "employeeCategory.text" },
    { field: "employmentType", sortValue: "employmentType.text" },
    { field: "functionalJobTitle", sortValue: "functionalJobTitle.text" },
  ];
  filterString: string;
  dialogRef: any;

  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'copy',
      name: this.translate.instant('Copy'),
      icon: 'copy'
    }
  ];

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    public router: Router,
    private positionsService: PositionsService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    featureService: FeatureService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
  ) {
    super(featureService, features.POSITIONS)
  }

  ngOnInit(): void {
    this.filterCategories.splice(this.filterCategories.findIndex(v => v.field === "employees"), 1)

    this.getPositions();
    this.getFields(db_tables.Positions);
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
        .pipe(
            finalize( () => {
            this.sortingColumns = false;
            this.getTableFieldPermissions();
            })
        )
        .subscribe(
            pagedFieldList => {
                this.fieldList = pagedFieldList.data;

                this.fieldList.forEach(
                field => {
                    let index = this.columns.findIndex( column => column.tableId === field.id )

                    if(index !== -1){
                    if(field.enabled === false){
                        this.columns.splice(index, 1);
                    }

                    if(field.name !== null){
                        this.columns[index].title = field.name;
                    }
                    }
                }
                );
            }
        );
    }

    getTableFieldPermissions() {
        let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(db_tables.Positions);

        tableFieldPermissions.forEach(
            tableFieldPermission => {
            let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )

            if(index !== -1){
                if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
                this.columns.splice(index, 1);
                }
            }
            }
        )
    }

  redirectOrgChart() {
    this.router.navigate([`${routes.REPORTS}${routes.ORG_CHART}`, { type: "positions" , isEditable: "true"}]);
  }

  getPositions(): void {
    this.isLoading = true;

    this.getPositionsRequest = this.positionsService.getPositions(this.skip, String(this.pageSize), this.searchFilterString, this.sortString, 'true', this.filterToggleDetails.checked.toString())
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getPositions();
  }

  public sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;

      if (sort[0].dir === undefined) {
          this.sortString = null;
      } else {
          //use regex to get column field to sort with
          let field: any;

          //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
          (sort[0].field).match(/.+(?=\.)/) === null
              ? field = sort[0].field
              : field = (sort[0].field).match(/.+(?=\.)/);
          this.sortString = `${field}-${sort[0].dir}`;
      }

      this.getPositions();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(item => defer(() => this.positionsService.deletePosition(item)));

    this.overlayService.show();

    forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.clearSelectedItems = !this.clearSelectedItems;
                this.getPositions();
                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
  }

  deletePosition(positionId: string) {
      this.overlayService.show();

      this.positionsService.deletePosition(positionId)
          .pipe(
              finalize(() => this.overlayService.hide())
          )
          .subscribe(
              res => {
                  this.getPositions();
                  this.snackbarService.openSnackBar('Position deleted successfully', 'clear', 'success');
              }
          );
  }


  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      // this.filterString = filterString;
      this.skip = 0;;
      this.getPositions();
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `(Name like "${this.searchValue}") OR (EmployeeId like "${this.searchValue}") OR (EmployeeFirstName like "${this.searchValue}") OR (EmployeeLastName like "${this.searchValue}")`;
    this.getPositionsRequest.unsubscribe();
    this.getPositions();
  }

  createPositionFormData(position: PositionVerbose) {
      return {
          id: position ? position.id : null,
          clientPositionId: position ? position.clientPositionId : null,
          startDate: position ? position.startDate : null,
          endDate: position ? position.endDate : null,
          organization: position ? position.organization : null,
          assistant: position ? position.assistant : false,
          functionalJobTitle: position && position.functionalJobTitle ? position.functionalJobTitle.id : null,
          region: position && position.region ? position.region.id : null,
          division: position && position.division ? position.division.id : null,
          subDivision: position && position.subDivision ? position.subDivision.id : null,
          department: position && position.department ? position.department.id : null,
          workLocation: position ? position.workLocation?.id : null,
          workRotationStartDate: position ? position.workRotationStartDate : null,
          workRotation: position ? position.workRotation?.id : null,
          jobGroup: position && position.jobGroup ? position.jobGroup.id : null,
          jobFamily: position && position.jobFamily ? position.jobFamily.id : null,
          jobBand: position && position.jobBand ? position.jobBand.id : null,
          jobType: position && position.jobType ? position.jobType.id : null,
          jobStep: position && position.jobStep ? position.jobStep.id : null,
          projectTeam: position && position.projectTeam ? position.projectTeam.id : null,
          marketPositionTitle: position && position.marketPositionTitle ? position.marketPositionTitle.id : null,
          marketPosition: position && position.marketPosition ? position.marketPosition.id : null,
          marketView: position && position.marketView ? position.marketView.id : null,
          unionCode: position && position.unionCode ? position.unionCode.id : null,
          shiftCode: position && position.shiftCode ? position.shiftCode.id : null,
          employmentGroup: position && position.employmentGroup ? position.employmentGroup.id : null,
          employeeCategory: position && position.employeeCategory ? position.employeeCategory.id : null,
          employmentType: position && position.employmentType ? position.employmentType.id : null,
          enforceSlotLimit: position ? position.enforceSlotLimit : false,
          numberOfSlots: position ? position.numberOfSlots : 1,
          slotsAvailable: position ? position.slotsAvailable : null,
          slotsFilled: position ? position.slotsFilled : null,
          parentPosition: position?.parentPosition?.id ? position.parentPosition.id : null,
          name: position ? position.name : null,
          costCenters: position ? position.costCenters : null,
          fte: position ? position.fte : null,
          udl1: position ? position.udl1?.id : null,
          udl2: position ? position.udl2?.id : null,
          udt1: position ? position.udt1 : null,
          udt2: position ? position.udt2 : null,
          udd1: position ? position.udd1 : null,
          udd2: position ? position.udd2 : null,
          changeReason: null,
          changeReasonComments: null,
      };
  }

  openCopyFormDialog(rowActionEventOutput: RowActionEventOutput) {
      let formId = 'frm_HYslRQKc5uefwW';
      let changeReasonFormId: string = 'frm_cwTMKxheRUNZud';

      let formData = {
        name: null,
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        formTitle: `${this.translate.instant('CopyPosition')}`,
        formId: formId,
        changeReasonFormId: changeReasonFormId,
        formData: formData
      };

      this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

      const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
          this.getPositionDetails(event, rowActionEventOutput.rowData)
      });
  }

  getPositionDetails(copyFormData: { name: any[], changeReason: string, changeReasonComments: string }, position: any) {
      this.positionsService.getPosition(position.id)
      .pipe(
          finalize(() => this.isLoading = false)
      )
      .subscribe(
          res => {
              let formData = this.createPositionFormData(res);

              formData.name = copyFormData.name;
              formData.changeReason = copyFormData.changeReason;
              formData.changeReasonComments = copyFormData.changeReasonComments;
              this.createPosition(formData);
          }
      );
  }

  createPosition(formData) {
      this.overlayService.show();

      this.positionsService.postPosition(formData)
      .pipe(
          finalize(() => this.overlayService.hide())
      )
      .subscribe(
          (res) => {
              this.snackbarService.openSnackBar(`${this.translate.instant('CopiedSuccessfully')}`, 'clear', 'success');
              this.dialogRef.close();
              this.router.navigate([`${routes.SITE_SETTINGS}/${routes.POSITIONS}/${routes.EDITOR}/${res.positionId}`]);
          }
      );
  }

  navigateToPosition(position?: Position) {
    if(position){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.POSITIONS}${routes.EDITOR}/${position?.id}`], {relativeTo: this.route});
    }
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getPositions();
  }

  gridStateChange(gridState: GridState) {
        if(this.pageSize !== gridState.pageSize) {
            this.pageSize = gridState.pageSize;
        }
        if(this.skip !== gridState.skip) {
            this.skip = gridState.skip;
        }
        if(this.sortString !== gridState.sortString) {
            this.sortString = gridState.sortString;
        }
        if(this.filterString !== gridState.filter) {
            this.filterString = gridState.filter;
            this.skip = 0;
        }

        if(gridState.filterToggleDetails === null) {
            this.filterToggleDetails.checked = false;
            this.skip = 0;
        } else if(this.filterToggleDetails.checked !== gridState.filterToggleDetails.checked) {
            this.filterToggleDetails.checked = gridState.filterToggleDetails.checked;
            this.skip = 0;
        }
        
        if(this.searchValue !== gridState.searchValue) {
            let variants = [];
            this.searchFilterString = '';
            this.searchValue = gridState.searchValue;

            if(this.searchValue !== null) {
                variants = this.searchValue.split(' ').filter(value => value);
            }

            variants.map(variant => {
                if (variant) {
                    if (this.searchFilterString.length > 2) {
                        this.searchFilterString += ' AND ';
                    }
                    this.searchFilterString = `(Name like "${variant}") OR (EmployeeId like "${variant}") OR (EmployeeFirstName like "${variant}") OR (EmployeeLastName like "${variant}")`;
                }
            });

            this.skip = 0;
        }

        this.getPositionsRequest.unsubscribe();
        this.getPositions();
    }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IcimsIntegrationSettings, IntegrationEvent, IntegrationEventVerbose, IntegrationSettings, IntegrationType, PayworksTestConnectionResponse } from '../models/integrations.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EnvironmentService } from "@app/core/services/environment.service";
import { of } from 'rxjs';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }

    getIntegrationTypes(skip?: number, take?: number): Observable<PagedData<IntegrationType>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations`, {
            headers: headers,
            params: params
        })
    }


    getIntegrations(skip?: number, take?: number, filterString?: string, sort?: string): Observable<PagedData<IntegrationSettings>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings`, {
            headers: headers,
            params: params
        })
    }

    getIntegration(id: string): Observable<IntegrationSettings> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, {
            headers: headers
        })
    }

    deleteIntegration(id: string): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, {
            headers: headers
        })
    }

    createIntegration(integrationPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings`, integrationPayload, {
            headers: headers
        })
    }

    updateIntegration(id: string, integrationPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}`, integrationPayload, {
            headers: headers
        })
    }

    getIcimsIntegrationConfig(integrationId: string): Observable<IcimsIntegrationSettings> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${integrationId}/iCIMS`, {
            headers: headers
        })
    }

    updateIcimsIntegrationConfig(integrationId: string, integrationConfigPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings/${integrationId}/iCIMS`, integrationConfigPayload, {
            headers: headers
        })
    }


    getIntegrationConfig(): Observable<any> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/iCIMS/Config`, {
            headers: headers
        })
    }

    updateIntegrationConfig(integrationConfigPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/iCIMS/Config`, integrationConfigPayload, {
            headers: headers
        })
    }

    getIntegrationEvents(integrationId: any, skip?: number, take?: number, sort?: string, filter?: string): Observable<PagedData<IntegrationEvent>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        params = params.append('filter', `Integration.Id = "${integrationId}"`);

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Events`, {
            headers: headers,
            params: params
        })
    }

    getIntegrationEvent(integrationId: any): Observable<IntegrationEventVerbose> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Events/${integrationId}`, {
            headers: headers
        })
    }

    getIntegrationEventLogs(id: string, skip?: number, take?: number): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : 0;
        take ? params = params.append('take', take) : 100;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Events?integrationEventId=${id}`, {
            headers: headers,
            params: params
        })
    }

    getIntegrationMappings(integrationId: any, skip?: number, take?: number): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings`, {
            headers: headers,
            params: params
        })
    }

    createIntegrationMapping(integrationId: any, mappingPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings`, mappingPayload, {
            headers: headers
        })
    }

    updateIntegrationMapping(integrationId: any, mappingId: any, mappingPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings/${mappingId}`, mappingPayload, {
            headers: headers
        })
    }

    deleteIntegrationMapping(integrationId: any, mappingId: any): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/${integrationId}/RecordMappings/${mappingId}`, {
            headers: headers
        })
    }

    getIcimsMappingFields(id: string): Observable<string[]> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings/IcimsFields`, {
            headers: headers
        })
    }

    getIcimsMappingsHubbubTables(id: string): Observable<any> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings/HubbubTables`, {
            headers: headers
        });
    }

    getIcimsMappings(id: string, skip?: number, take?: number, filter?: string, sort?: string): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filter ? params = params.append('filter', filter) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings`, {
            headers: headers,
            params: params
        });
    }

    createIcimsMapping(id: string, mappingPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings`, mappingPayload, {
            headers: headers
        });
    }

    updateIcimsMapping(id: string, mappingId: string, mappingPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings/${mappingId}`, mappingPayload, {
            headers: headers
        });
    }

    deleteIcimsMapping(id: string, mappingId: string): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Mappings/${mappingId}`, {
            headers: headers
        });
    }

    testIcimsConnection(id: string): Observable<PayworksTestConnectionResponse> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/iCIMS/Test`, {}, {
            headers: headers
        });
    }

    /*
    .########.....###....##....##.##......##..#######..########..##....##..######.
    .##.....##...##.##....##..##..##..##..##.##.....##.##.....##.##...##..##....##
    .##.....##..##...##....####...##..##..##.##.....##.##.....##.##..##...##......
    .########..##.....##....##....##..##..##.##.....##.########..#####.....######.
    .##........#########....##....##..##..##.##.....##.##...##...##..##.........##
    .##........##.....##....##....##..##..##.##.....##.##....##..##...##..##....##
    .##........##.....##....##.....###..###...#######..##.....##.##....##..######.
    */
    getPayworksIntegrationConfig(id: string): Observable<any> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks`, {
            headers: headers
        })
    }

    updatePayworksIntegrationConfig(id: string, integrationConfigPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks`, integrationConfigPayload, {
            headers: headers
        })
    }

    getPayworksMappingFields(id: string): Observable<string[]> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings/PayworksFields`, {
            headers: headers
        })
    }

    getPayworksMappings(id: string, skip?: number, take?: number, filter?: string, sort?: string): Observable<any> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filter ? params = params.append('filter', filter) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings`, {
            headers: headers,
            params: params
        });
    }

    createPayworksMapping(id: string, mappingPayload): Observable<any> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings`, mappingPayload, {
            headers: headers
        });
    }

    updatePayworksMapping(id: string, mappingId: string, mappingPayload): Observable<any> {
        return this.http
        .put<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings/${mappingId}`, mappingPayload, {
            headers: headers
        });
    }

    deletePayworksMapping(id: string, mappingId: string): Observable<any> {
        return this.http
        .delete<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings/${mappingId}`, {
            headers: headers
        });
    }

    testPayworksConnection(id: string): Observable<PayworksTestConnectionResponse> {
        return this.http
        .post<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Test`, {}, {
            headers: headers
        });
    }

    getPayworksMappingsHubbubTables(id: string): Observable<any> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}Integrations/Settings/${id}/Payworks/Mappings/HubbubTables`, {
            headers: headers
        });
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { environment } from '@environments/environment';
import { map, Observable, of } from 'rxjs';
import { AssignEmployeeTimeOffType, EmployeeTimeOffType, ManualAdjustmentsHistory, SubmitTimeOffDetail, SubmitTimeOffTypeHours, TimeOffDetail, TimeOffTypeLog } from '../models/leave-request.model';
import {EnvironmentService} from "@app/core/services/environment.service";
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeLeaveService {

  constructor(
    private datePipe: DatePipe,
    private http: HttpClient, 
    private envService: EnvironmentService
  ) {}

  getEmployeeTimeOffTypes(employeeId: string): Observable<EmployeeTimeOffType[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${api_routes.TYPES}`, {
      headers: headers
    })
  }

  assignEmployeeTimeOffTypes(employeeId: string, assignEmployeeTimeOffType: AssignEmployeeTimeOffType[]): Observable<{succeeded: true, errors: string[]}> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${api_routes.TYPES}`, assignEmployeeTimeOffType, {
      headers: headers
    })
  }

  getTimeOffDetail(employeeId: string): Observable<TimeOffDetail> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIME_OFF_DETAIL}`, {
      headers: headers
    })
  }

  updateTimeOffDetail(employeeId: string, submitTimeOffDetail: SubmitTimeOffDetail): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIME_OFF_DETAIL}`, submitTimeOffDetail, {
      headers: headers
    })
  }

  updateTimeOffTypeHours(employeeId: string, timeOffTypeId: string, submitTimeOffTypeHours: SubmitTimeOffTypeHours): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${timeOffTypeId}/${api_routes.MANUAL_ADJUSTMENTS}`, submitTimeOffTypeHours, {
      headers: headers
    })
  }

  getTimeOffTypeManualAdjustHistory(employeeId: string, timeOffTypeId: string): Observable<PagedData<ManualAdjustmentsHistory>> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${timeOffTypeId}/${api_routes.MANUAL_ADJUSTMENTS}/${api_routes.HISTORY}`, {
      headers: headers
    })
  }

  getCalculationLog(employeeId: string, timeOffTypeId: string): Observable<TimeOffTypeLog[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${api_routes.TYPES}/${timeOffTypeId}`, {
      headers: headers
    })
    // .pipe(
    //   map(
    //     (response) => this.formatData(response)
    //   )
    // )
  }

  formatData(data: TimeOffTypeLog[]): TimeOffTypeLog[]{
    data.forEach( (element, index) => {
      element.on = this.datePipe.transform(element.on, 'longDate');
      element.createdOn = this.datePipe.transform(element.createdOn, 'long');
    });

    return data;
  }

  public getEmployeeLeave(): Observable<any> {
    return of({
      daysRemaining: 11,
      daysUsed: 3,
      leaveRequests: [
        {
          reason: 'Maternity',
          type: 'Leave',
          from: '13-06-2021',
          to: '20-06-2021',
          status: 'Pending'
        },
        {
          reason: 'Fever and headache',
          type: 'Sick',
          from: '24-05-2021',
          to: '24-05-2021',
          status: 'Approved'
        },
        {
          reason: 'Family Holiday',
          type: 'Leave',
          from: '02-03-2021',
          to: '15-03-2021',
          status: 'Approved'
        },
      ]
    });
  }
}

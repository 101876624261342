import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { WorkRotationService } from './services/work-rotation.service';
import { finalize } from 'rxjs/operators';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { WorkRotation } from './models/work-rotation.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { defer, forkJoin } from 'rxjs';
import { GridState } from '@app/shared/services/grid-state/grid-state.model';

@Component({
  selector: 'app-work-rotations',
  templateUrl: './work-rotations.component.html',
  styleUrls: ['./work-rotations.component.scss']
})
export class WorkRotationsComponent extends SecurityProtectedBase implements OnInit {
  gridId = 'work-rotations';
  public routes: typeof routes = routes;
  public gridDataResult: GridDataResult;
  public bindingType: String = "array";
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  clearSelectedItems: boolean = false;
  public searchValue: string;
  private sortString: string = "name-asc";
  public columns: any[] = [
    {
      field: 'name',
      title: 'Name',
      dataType: "String"
    },
    {
      field: 'positionsUsingRotation',
      title: `Positions Using ${this.translate.instant('WorkRotation')}`,
      dataType: "String"
    }
  ];
  filterCategories: any[] = [
    {
      field: 'name',
      title: 'Name',
      dataType: "String"
    },
    {
      field: 'positionsUsingRotation',
      title: `Positions Using ${this.translate.instant('WorkRotation')}`,
      dataType: "Number"
    }
  ];
  sortableColumns: any[] = [
    { field: "name", sortValue: "name" },
    { field: "positionsUsingRotation", sortValue: "positionsUsingRotation" }
  ];
    
  searchFilterString: any;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private workRotationService: WorkRotationService,
    private router: Router,
    private route: ActivatedRoute,
    featureService: FeatureService,
  ) {
    super(featureService, features.WORK_ROTATIONS)
  }

  ngOnInit(): void {
    this.getWorkRotations();
  }

  getWorkRotations(): void {
    this.getRequest?.unsubscribe();
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
        filter = this.searchFilterString;
    }
    if(this.filterString) {
        if(filter){
            filter = `${filter} AND ${this.filterString}`;
        }
        else {
            filter = `${this.filterString}`;
        }
    }

    this.getRequest = this.workRotationService.getWorkRotations(this.skip, String(this.pageSize), filter, this.sortString)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.gridDataResult = {
                data: res.data,
                total: res.total,
            };
        }
    );
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(item => defer(() => this.workRotationService.deleteWorkRotation(item)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.clearSelectedItems = !this.clearSelectedItems;
        this.getWorkRotations();
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      },
      err => {
          this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }
  
  gridStateChange(gridState: GridState) {
    if(this.pageSize !== gridState.pageSize) {
        this.pageSize = gridState.pageSize;
    }
    if(this.skip !== gridState.skip) {
        this.skip = gridState.skip;
    }
    if(this.sortString !== gridState.sortString) {
        this.sortString = gridState.sortString;
    }
    if(this.filterString !== gridState.filter) {
        this.filterString = gridState.filter;
        this.skip = 0;
    }
    
    if(this.searchValue !== gridState.searchValue) {
      let variants = [];
      this.searchFilterString = '';
      this.searchValue = gridState.searchValue;

      if(this.searchValue !== null) {
          variants = this.searchValue.split(' ').filter(value => value);
      }

      variants.map(variant => {
          if (variant) {
              if (this.searchFilterString.length > 2) {
                  this.searchFilterString += ' AND ';
              }
              this.searchFilterString = `(Name like "${variant}")`;
          }
      });

      this.skip = 0;
    }

    this.getWorkRotations();
  }

  navigateToWorkRotation(workRotation?: WorkRotation) {
    if(workRotation){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}${routes.EDITOR}/${workRotation?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PayworksTestConnectionResponse } from '@app/modules/site-settings-integrations/models/integrations.model';
import { IntegrationsService } from '@app/modules/site-settings-integrations/services/integrations.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-payworks-test-connection',
  templateUrl: './payworks-test-connection.component.html',
  styleUrls: ['./payworks-test-connection.component.scss']
})
export class PayworksTestConnectionComponent implements OnInit {
  @Input() public integrationId: string;
  @Input() public integrationType: string;
  testConnectionResponse: PayworksTestConnectionResponse;
  isLoading: boolean;
  
  constructor(
    private integrationService: IntegrationsService,
  ) { }

  ngOnInit(): void {

  }

  testConnection() {
    this.isLoading = true;
    this.testConnectionResponse = null;
    
    if(this.integrationType === 'Payworks') {
      this.integrationService.testPayworksConnection(this.integrationId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => {
        this.testConnectionResponse = res;
      });
    }
    else if(this.integrationType === 'IcimsInbound' || this.integrationType === 'iCIMS') {
      this.integrationService.testIcimsConnection(this.integrationId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => {
        this.testConnectionResponse = res;
      });
    }
    
  }

}

import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { routes } from '@app/consts';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ToilOccurrenceReportService } from '../../services/toil-occurrence-report.service';
import { ToilOccurrenceRecord } from '@app/modules/reports/components/toil-occurrence-report/models/toil-occurrence-report.model';

@Component({
    selector: 'app-toil-occurrence-details-data-grid',
    templateUrl: './toil-occurrence-report-details-data-grid.component.html',
    styleUrls: ['./toil-occurrence-report-details-data-grid.component.scss']
})
export class ToilOccurrenceReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;

    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    public pageSizes: (PageSizeItem | number)[] = [
        5,
        10,
        20,
        {
            text: 'All',
            value: 100000,
        },
    ];
    isLoading: boolean;
    sortString: string;
    filterString: string;
    toilRecords: ToilOccurrenceRecord[];

    private filterInactive = true;
    public columns: any[] = [
        {field: 'employeeName', title: this.translate.instant('ToilOccurrence-EmployeeName'), dataType: 'String' , tableId: 'ToilOccurrence-EmployeeName'},
        {field: 'managerName', title: this.translate.instant('ToilOccurrence-ManagerName'), dataType: 'String', tableId: 'ToilOccurrence-EmployeeName'},
        {field: 'toilTypeName', title: this.translate.instant('ToilOccurrence-PolicyName'), dataType: 'String', tableId: 'ToilOccurrence-PolicyName'},
        {field: 'hours', title: this.translate.instant('ToilOccurrence-OutstandingOccurrence'), dataType: 'Number', tableId: 'ToilOccurrence-OutstandingOccurrence'},
    ];
    public state: State = {
        skip: 0,
        take: 100,
    };
    public query: Observable<GridDataResult>;
    private stateChange = new BehaviorSubject<State>(this.state);
    public allData = (): Observable<GridDataResult> => {
        const exportState: State = {
            skip: 0,
            take: 100
        };

        return from(this.toilOccurrenceReportService.queryAll(
            exportState,
            null,
            null,
            this.filterInactive
        ));
    }

    constructor(
        private translate: TranslateService,
        private ngZone: NgZone,
        private toilOccurrenceReportService: ToilOccurrenceReportService,
        private sanitiseGridDataService: SanitiseGridDataService,
    ) {

        this.query = this.stateChange.pipe(
            tap((state) => {
                this.state = state;
                this.isLoading = true;
            }),
            switchMap((state) => toilOccurrenceReportService.fetch(state, this.filterString, this.sortString,
                false, this.filterInactive)),
            tap(() => {
                this.isLoading = false;
            })
        );
        // Bind 'this' explicitly to capture the execution context of the component.
        this.allData = this.allData.bind(this);

    }

    ngOnInit(): void {
    }

    public pageChange(state: PageChangeEvent): void {
        this.stateChange.next(state);
    }

    public async onExcelExport(e: ExcelExportEvent): Promise<void> {
        e = this.sanitiseGridDataService.sanitise(e);
    }

    toggleFilterInactive($event: MatSlideToggleChange): void {
        this.filterInactive = $event.checked;
        this.stateChange.next(this.state);
    }

}

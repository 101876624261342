import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { Table } from '@app/modules/security-setup/models/table.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { PayworksIntegrationMapping } from '@app/modules/site-settings-integrations/models/integrations.model';
import { IntegrationsService } from '@app/modules/site-settings-integrations/services/integrations.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin, from, map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-integration-mappings-dialog',
    templateUrl: './integration-mappings-dialog.component.html',
    styleUrls: ['./integration-mappings-dialog.component.scss']
})
export class IntegrationMappingsDialogComponent implements OnInit {
    public integrationId: string;
    public integrationMapping?: PayworksIntegrationMapping;
    public form = this.fb.group(
        {
            table: [null, Validators.required],
            tableField: [null, Validators.required],
            integrationFieldId: [null, Validators.required],
            transformations: this.fb.array([])
        }
    );
    public tableGroups: Array<any>;
    public selectedTable: Table;
    public fieldList: Array<TableField>;
    public selectedField: TableField;
    loadingTables: boolean;
    tables: any;
    loadingFields: boolean;
    icimsFields: string[] = [];
    filteredIcimsFields: Observable<string[]>;

    constructor(
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private securitySetupService: SecuritySetupService,
        private integrationService: IntegrationsService,
        private dialogRef: MatDialogRef<IntegrationMappingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.integrationId = data.integrationId;
        this.integrationMapping = data.integrationMapping;
        this.createForm();
    }

    ngOnInit(): void {
        this.filteredIcimsFields = this.form.controls['integrationFieldId'].valueChanges.pipe(
            startWith(''),
            map((value: string) => this._filter(value || '')),
        );

        this.getTablesAndFields();
        this.getIcimsFields();
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.icimsFields.filter(option => option.toLowerCase().includes(filterValue));
    }

    createForm() {
        if (this.integrationMapping) {
            this.form.get('table').setValue(this.integrationMapping.table?.id);
            this.form.get('tableField').setValue(this.integrationMapping.tableField?.id);
            this.form.get('integrationFieldId').setValue(this.integrationMapping.integrationFieldId);
        }

        if (this.integrationMapping?.transformations && this.integrationMapping.transformations.length > 0) {
            this.integrationMapping.transformations.forEach(transformation => {
                this.addTransformation(transformation);
            });
        }
    }

    removeTransformation(index) {
        (this.form.get('transformations') as UntypedFormArray).removeAt(index);
    }

    addTransformation(transformation?: { hubbub: string, integrationTarget: string }) {
        (this.form.get('transformations') as UntypedFormArray).push(this.fb.group({
            hubbub: [transformation ? transformation.hubbub : '', Validators.required],
            integrationTarget: [transformation ? transformation.integrationTarget : '', Validators.required]
        }));
    }

    getIcimsFields() {
        this.integrationService.getIcimsMappingFields(this.integrationId).subscribe(
            res => {
                this.icimsFields = res;
            }
        );
    }

    getTablesAndFields() {
        this.loadingTables = true;

        this.integrationService.getIcimsMappingsHubbubTables(this.integrationId)
            .pipe(
                finalize(() => {
                    this.loadingTables = false;
                    this.getTableFields();
                }
                )
            )
            .subscribe(
                res => {
                    this.tables = res.data;
                }
            );
    }

    getTableFields() {
        this.loadingFields = true;

        let observables: Observable<any>[] = [];

        this.tables.forEach(table => {
            observables.push(from(this.securitySetupService.getAllFields(table.id)));
        });

        forkJoin(observables)
            .pipe(
                finalize(() => {
                    this.loadingFields = false;
                    this.createTablesMap();
                }
                )
            )
            .subscribe(
                (res) => {
                    res.forEach((fields, index) => {
                        this.tables[index].fields = fields.data;
                    }
                    )
                }
            );
    }

    createTablesMap() {
        let tableList = this.tables;
        let tableMap = [];
        tableList.map(table => {
            if (table.parentTable != null) {
                let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

                if (groupIndex >= 0) {
                    tableMap[groupIndex].tables.push(table);
                } else {
                    tableMap.push({ groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table] });
                }
            } else {
                tableMap.push({ groupName: table.name, groupId: table.id, tables: [table] });
            }
        });
        this.tableGroups = tableMap;

        if (this.integrationMapping.tableField?.id) {
            this.selectedTable = this.tables.find(table => table.id === this.integrationMapping.table.id);
        }
    }

    handleSelectTableClick(tableId) {
        this.selectedTable = this.tables.find(table => table.id === tableId);
        this.selectedField = null;
        this.form.get('tableField').setValue(null);
    }

    handleSelectFieldClick(tableField) {
        this.selectedField = tableField;
        this.form.get('tableField').setValue(tableField.id);
    }

    save() {
        this.overlayService.show();

        let mappingPayload = this.form.value;

        if (this.integrationMapping) {
            this.integrationService.updateIcimsMapping(this.integrationId, this.integrationMapping.id, mappingPayload)
            .subscribe(
                {
                    next: (v) => {
                        this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');
                        this.dialogRef.close(true);
                    },
                    error: (e) => this.overlayService.hide(),
                    complete: () => this.overlayService.hide()
                }
            );
        } else {
            this.integrationService.createIcimsMapping(this.integrationId, mappingPayload)
            .subscribe(
                {
                    next: (v) => {
                        this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');
                        this.dialogRef.close(true);
                    },
                    error: (e) => this.overlayService.hide(),
                    complete: () => this.overlayService.hide()
                }
            );
        }
    }

    close() {
        this.dialogRef.close();
    }

    protected readonly fieldTypes = fieldTypes;

}


import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CountryConfiguration, CountryConfigurationVerbose } from '../models/country-configuration.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class CountryConfigurationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCountries(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<PagedData<CountryConfiguration>> {
    let params = new HttpParams();
    skip !== null ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.COUNTRIES}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  getCountryConfig(countryId: string): Observable<CountryConfigurationVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.COUNTRIES}/${countryId}`, {
      headers: headers
    });
  }

  updateCountryConfig(countryId: string, countryConfigUpdate: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.COUNTRIES}/${countryId}`, countryConfigUpdate,{
      headers: headers
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";
import { EmployeeIntegrationDetail, EmployeeIntegrationMappingVerbose } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-integration-details/models/employee-integration-details.model";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeIntegrationDetailsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getIntegrationDetails(employeeId: string, asOf?: string): Observable<EmployeeIntegrationDetail> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_DETAIL}`, {
      params: params,
      headers: headers
    });
  }

  updateIntegrationDetails(employeeId: string, employeeIntegrationDetailsSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_DETAIL}`, employeeIntegrationDetailsSubmit, {
      headers: headers
    });
  }

  getIntegrationMappings(employeeId: string, take?: string, skip?: number, sort?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sort ? params = params.append('sort', sort) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_MAPPINGS}`, {
      headers: headers,
      params: params
    })
  }

  deleteIntegrationMapping(employeeId: string, id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_MAPPINGS}/${id}`, {
      headers: headers
    });
  }

  getIntegrationMapping(employeeId: string, id: string): Observable<EmployeeIntegrationMappingVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_MAPPINGS}/${id}`, {
      headers: headers
    });
  }

  updateIntegrationMapping(employeeId: string, employeeIntegrationMapping): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_MAPPINGS}/${employeeIntegrationMapping.id}`, employeeIntegrationMapping, {
      headers: headers
    });
  }

  createIntegrationMapping(employeeId: string, employeeIntegrationMapping): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.INTEGRATION_MAPPINGS}`, employeeIntegrationMapping, {
      headers: headers
    });
  }
}

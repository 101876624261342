<h5 mat-dialog-title>
    {{ 'Template-Replacement' | translate: {Default: "Replacement"} }}
</h5>

<mat-dialog-content>
    <form class="replacement" [formGroup]="replacement">
        <mat-form-field appearance="fill">
            <mat-label appLocalizationPopupDirective localizationCode="Template-Identifier">
                {{ 'Template-Identifier' | translate: {Default: "Identifier"} }}
            </mat-label>

            <input matInput type="text" formControlName="identifier">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label appLocalizationPopupDirective localizationCode="Template-Table">
                {{ 'Template-Table' | translate: {Default: "Table"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="table" class="mat-form-field">
                
                <mat-option value=""></mat-option>

                <mat-option *ngFor="let table of tables" [value]="table.id">
                    {{table.name}}
                </mat-option>

            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="isTableFlat">
            <mat-form-field appearance="fill">
                <mat-label appLocalizationPopupDirective localizationCode="Template-TimelineOption">
                    {{ 'Template-TimelineOption' | translate: {Default: "Timeline Option"} }}
                </mat-label>

                <mat-select disableOptionCentering formControlName="timelineOption" class="mat-form-field">
                    
                    <mat-option value=null></mat-option>
    
                    <mat-option *ngFor="let option of timelineOptions" [value]="option.id">
                        {{option.name}}
                    </mat-option>
    
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="replacement.get('table').value !== '' && !isTableFlat">
            <div class="slide-toggle-container" appLocalizationPopupDirective localizationCode="Template-DisplayAsTable" >
                <mat-slide-toggle setColor="primaryColour" formControlName="displayAsTable">
                    {{ 'Template-DisplayAsTable' | translate: {Default: "Display As Table"} }}
                </mat-slide-toggle>
            </div>
        </ng-container>

        <div class="bottom">
            <div class="fields" formArrayName="fields" *ngIf="replacement.get('table').value !== null && replacement.get('table').value !== ''">
                <h6 *ngIf="!isTableFlat">
                    {{ 'Template-Fields' | translate: {Default: "Fields"} }}
                </h6>
                <h6 *ngIf="isTableFlat">
                    {{ 'Template-Field' | translate: {Default: "Field"} }}
                </h6>
                <div class="field" *ngFor="let field of getFields().controls; let j = index" [formGroupName]="j">
                    
                    <div class="top">
                        <div class="left">
                            <mat-form-field appearance="fill">
                                <mat-label appLocalizationPopupDirective localizationCode="Template-Field">
                                    {{ 'Template-Field' | translate: {Default: "Field"} }}
                                </mat-label>
    
                                <mat-select disableOptionCentering formControlName="id" class="mat-form-field">
                                    
                                    <mat-option value=""></mat-option>
                    
                                    <mat-option *ngFor="let field of getSelectedTableFields()" [value]="field.id">
                                        {{field.name}}
                                    </mat-option>
                    
                                </mat-select>
                            </mat-form-field>

                            <div class="copy-field-name" *ngIf="replacement.get('table').value !== '' && !isTableFlat && !replacement.get('displayAsTable').value">
                                <div class="field-name" appLocalizationPopupDirective localizationCode="Template-CopyFieldName">
                                    {{ 'Template-CopyFieldName' | translate: {Default: "Copy this value and paste it into the String Format field"} }}
                                </div>
                                <mat-icon 
                                    setColor 
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                    ]"
                                    class="material-icons-outlined clickable-icon" 
                                    (click)="copyFieldName(j)"
                                    matTooltip="Copy Field Name"
                                >content_copy</mat-icon>
                            </div>

    
                            <ng-container *ngIf="!isTableFlat">
                                <mat-form-field appearance="fill">
                                    <mat-label appLocalizationPopupDirective localizationCode="Template-Sort">
                                        {{ 'Template-Sort' | translate: {Default: "Sort"} }}
                                    </mat-label>
        
                                    <mat-select disableOptionCentering formControlName="sortDirection" class="mat-form-field">
                                        
                                        <mat-option value=""></mat-option>
                        
                                        <mat-option value="asc">
                                            {{ 'Ascending' | translate: {Default: "Ascending"} }}
                                        </mat-option>
        
                                        <mat-option value="desc">
                                            {{ 'Descending' | translate: {Default: "Descending"} }}
                                        </mat-option>
                        
                                    </mat-select>
                                </mat-form-field>
        
                                <mat-form-field appearance="fill">
                                    <mat-label appLocalizationPopupDirective localizationCode="Filter-Operator">
                                        {{ 'Filter-Operator' | translate: {Default: "Filter Operator"} }}
                                    </mat-label>
        
                                    <mat-select disableOptionCentering formControlName="filterOperator" class="mat-form-field">
                                        
                                        <mat-option value=""></mat-option>
                        
                                        <mat-option *ngFor="let filterOperator of getCorrectFilterOperators(j)" [value]="filterOperator.value">
                                            {{filterOperator.label}}
                                        </mat-option>
                        
                                    </mat-select>
                                </mat-form-field>
        
                                <mat-form-field appearance="fill">
                                    <mat-label appLocalizationPopupDirective localizationCode="Template-FilterValue">
                                        {{ 'Template-FilterValue' | translate: {Default: "Filter Value"} }}
                                    </mat-label>
                        
                                    <input matInput type="text" formControlName="filterValue">
                                </mat-form-field>
                            </ng-container>
    
                        </div>
                        <div class="right" *ngIf="!isTableFlat">
                            <mat-icon 
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                                class="material-icons-outlined clickable-icon" 
                                (click)="removeField(j)"
                                matTooltip="Remove Field"
                            >delete</mat-icon>
                        </div>
                    </div>
    
                    <mat-divider *ngIf="!isTableFlat"></mat-divider>
                </div>
        
                <button mat-stroked-button *ngIf="!isTableFlat" class="add-field-btn" setColor="primaryColour" color="primary" (click)="addField()">
                    <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddField' | translate: {Default: "Add Field"} }}
                </button>
            </div>

            <ng-container *ngIf="replacement.get('table').value !== '' && !isTableFlat">
                <ng-container *ngIf="!replacement.get('displayAsTable').value">
                    <mat-form-field appearance="fill" hidden>
                        <mat-label appLocalizationPopupDirective localizationCode="Template-Delimiter">
                            {{ 'Template-Delimiter' | translate: {Default: "Delimiter"} }}
                        </mat-label>
            
                        <input matInput type="text" formControlName="delimiter">
                    </mat-form-field>
        
                    <mat-form-field class="string-format-field" appearance="fill">
                        <mat-label appLocalizationPopupDirective localizationCode="Template-StringFormat">
                            {{ 'Template-StringFormat' | translate: {Default: "String Format"} }}
                        </mat-label>

                        <textarea
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="30"
                            formControlName="stringFormat">
                        </textarea>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </div>


        <mat-form-field appearance="fill" hidden>
            <mat-label appLocalizationPopupDirective localizationCode="Template-Filter">
                {{ 'Template-Filter' | translate: {Default: "Filter"} }}
            </mat-label>

            <input matInput type="text" formControlName="filter">
        </mat-form-field>

        <mat-form-field appearance="fill" hidden>
            <mat-label appLocalizationPopupDirective localizationCode="Template-Sort">
                {{ 'Template-Sort' | translate: {Default: "Sort"} }}
            </mat-label>

            <input matInput type="text" formControlName="sort">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!replacement.valid">Save</button>
</mat-dialog-actions>

<app-layout>
  <div class="content">
    <ng-container *ngIf="isLoadingPermissions; else loadedPermissions">
      <mat-card>
        <mat-card-content>
          <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
          ></ngx-skeleton-loader>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <ng-template #loadedPermissions>
      <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="Talent">{{ 'Talent' | translate: {Default: "Talent"} }}</h5>
  
            <button appLocalizationPopupDirective localizationCode="CreateNewEmployee" mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="editOrCreateEmployee()" *appTableAccess="[tableCreateAccessObj]">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
                {{ 'CreateNewEmployee' | translate: {Default: "Create New Employee"} }}
            </button>
        </mat-card-title>
        <mat-card-content>
          <app-data-grid-comp
            [gridId]="gridId"
            hideActionButtons="true"
            [columns]="columns"
            [filterCategories]="filterCategories"
            [bindingType]="bindingType"
            [gridDataResult]="gridDataResult"
            [isLoading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            [rowSelectionSettings]="{
                canSelectRows: false,
                selectableMode: 'multiple',
                selectRowBy: 'id'
            }"
            clickableRows="true"
            [isLoading]="isLoading"
            searchable="true"
            [sortable]="{
                mode: 'multiple'
            }"
            [sortableColumns]="sortableColumns"
            [filterToggleDetails]="filterToggleDetails"
            (emitOpenDialogEvent)="editOrCreateEmployee($event)"
            (emitGridStateChangeEvent)="gridStateChange($event)"
          ></app-data-grid-comp>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </div>
</app-layout>

<ng-container>
    <button mat-button [matMenuTriggerFor]="menu">
        <ng-container *ngIf="currentCulture && currentEmployeeDetails.userType === 'User'; else showPlanetIcon">
            <!-- <img xFlex class="flag-icon" src="assets/country-flags/svg/{{currentCulture.id}}.svg"> -->
            <img xFlex class="flag-icon" [src]="isSpecialUrl() && isEnGbCulture(currentCulture) ? 'assets/icons/world-icon.png' : 'assets/country-flags/svg/' + currentCulture.id + '.svg'">
        </ng-container>
        <ng-template #showPlanetIcon>
            <img fxFlex class="planet-icon" src="assets/icons/world-icon.png">
        </ng-template>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <div class="coverage-container" *ngIf="cultures$ | withLoading | async as cultures">
            <ng-template [ngIf]="cultures.value">
                <button 
                    class="culture-button"
                    *ngFor="let culture of cultures.value"  
                    mat-menu-item 
                    (click)="setCurrentCulture(culture)">
                        <div>{{culture.text}}</div>
                        <img class="flag" 
                             [src]="isSpecialUrl() && isEnGbCulture(culture) ? 'assets/icons/world-icon.png' : 'assets/country-flags/svg/' + culture.id + '.svg'">
                        <mat-icon class="material-icons-outlined selected-icon" *ngIf="isCurrentCulture(culture)">check_circle</mat-icon>
                </button>
            </ng-template>
            <ng-template [ngIf]="cultures.error">Error {{ obs.error }}</ng-template>
            <ng-template [ngIf]="cultures.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-menu>
</ng-container>

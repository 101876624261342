import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { routes } from '@app/consts';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ToilBalanceReportService } from '../../services/toil-balance-report.service';
import { ToilBalanceRecord } from '@app/modules/reports/components/toil-balance-report/models/toil-balance-report.model';

@Component({
    selector: 'app-toil-balance-details-data-grid',
    templateUrl: './toil-balance-report-details-data-grid.component.html',
    styleUrls: ['./toil-balance-report-details-data-grid.component.scss']
})
export class ToilBalanceReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;

    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    public pageSizes: (PageSizeItem | number)[] = [
        5,
        10,
        20,
        {
            text: 'All',
            value: 100000,
        },
    ];
    isLoading: boolean;
    sortString: string;
    filterString: string;
    toilRecords: ToilBalanceRecord[];

    private filterInactive = true;
    public columns: any[] = [
        {field: 'employeeName', title: this.translate.instant('ToilBalance-EmployeeName'), dataType: 'String' , tableId: 'ToilBalance-EmployeeName'},
        {field: 'managerName', title: this.translate.instant('ToilBalance-ManagerName'), dataType: 'String', tableId: 'ToilBalance-EmployeeName'},
        {field: 'policyName', title: this.translate.instant('ToilBalance-PolicyName'), dataType: 'String', tableId: 'ToilBalance-PolicyName'},
        {field: 'outstandingBalance', title: this.translate.instant('ToilBalance-OutstandingBalance'), dataType: 'Number', tableId: 'ToilBalance-OutstandingBalance'},
    ];
    public state: State = {
        skip: 0,
        take: 100,
    };
    public query: Observable<GridDataResult>;
    private stateChange = new BehaviorSubject<State>(this.state);
    public allData = (): Observable<GridDataResult> => {
        const exportState: State = {
            skip: 0,
            take: 100
        };

        return from(this.toilBalanceReportService.queryAll(
            exportState,
            null,
            null,
            this.filterInactive
        ));
    }

    constructor(
        private translate: TranslateService,
        private ngZone: NgZone,
        private toilBalanceReportService: ToilBalanceReportService,
        private sanitiseGridDataService: SanitiseGridDataService,
    ) {

        this.query = this.stateChange.pipe(
            tap((state) => {
                this.state = state;
                this.isLoading = true;
            }),
            switchMap((state) => toilBalanceReportService.fetch(state, this.filterString, this.sortString,
                false, this.filterInactive)),
            tap(() => {
                this.isLoading = false;
            })
        );
        // Bind 'this' explicitly to capture the execution context of the component.
        this.allData = this.allData.bind(this);

    }

    ngOnInit(): void {
    }

    public pageChange(state: PageChangeEvent): void {
        this.stateChange.next(state);
    }

    public async onExcelExport(e: ExcelExportEvent): Promise<void> {
        e = this.sanitiseGridDataService.sanitise(e);
    }

    toggleFilterInactive($event: MatSlideToggleChange): void {
        this.filterInactive = $event.checked;
        this.stateChange.next(this.state);
    }

}

<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="Integrations-Mappings">{{ 'Integrations-Mappings' | translate: {Default: "Integration Mapping"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="!loadingFields && !loadingTables; else loading">
        <form class="form" [formGroup]="form">
    
            <mat-form-field appLocalizationPopupDirective localizationCode="Integrations-HubbubHRTable" appearance="fill">
                <mat-label>{{ 'Integrations-HubbubHRTable' | translate: {Default: "Hubbub Table"} }}</mat-label>
                <mat-select disableOptionCentering formControlName="table" (valueChange)="handleSelectTableClick($event)" class="mat-form-field">
                    <mat-optgroup *ngFor="let group of tableGroups" [label]="group.groupName">
                        <mat-option *ngFor="let table of group.tables" [value]="table.id">
                            {{table.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appLocalizationPopupDirective localizationCode="Integrations-HubbubHRField" appearance="fill" *ngIf="selectedTable != null">
                <mat-label>{{ 'Integrations-HubbubHRField' | translate: {Default: "Hubbub Field"} }}</mat-label>
                <mat-select disableOptionCentering formControlName="tableField">
                    <mat-option *ngFor="let field of selectedTable.fields" [value]="field.id">
                        {{field.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appLocalizationPopupDirective localizationCode="Payworks-Field" appearance="fill">
                <mat-label>{{ 'Payworks-Field' | translate: {Default: "Payworks Field"} }}</mat-label>
                <mat-select disableOptionCentering formControlName="integrationFieldId">
                    <mat-option *ngFor="let field of payworksFields" [value]="field">
                        {{field}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="form-section">
                <div class="form-section-title">
                    <h6 appLocalizationPopupDirective localizationCode="Payworks-Transformations">{{ 'Payworks-Transformations' | translate: {Default: "Transformations"} }}</h6>

                    <button appLocalizationPopupDirective localizationCode="Payworks-AddTransformation" mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addTransformation()">
                        <mat-icon class="material-icons-outlined">add</mat-icon>
                        {{ 'Payworks-AddTransformation' | translate: {Default: "Add Transformation"} }}
                    </button>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <div formArrayName="transformations">
                            <div *ngFor="let transformation of form.controls.transformations.controls; let i=index" [formGroupName]="i" class="transformation-group">
                                <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="Hubbub-Value">
                                    <mat-label>{{ 'Hubbub-Value' | translate: {Default: "Hubbub Value"} }}</mat-label>
                                    <input matInput formControlName="hubbub">
                                </mat-form-field>
                                <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="Payworks-Value">
                                    <mat-label>{{ 'Payworks-Value' | translate: {Default: "Payworks Value"} }}</mat-label>
                                    <input matInput formControlName="integrationTarget">
                                </mat-form-field>
                                <mat-icon
                                    setColor
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                    ]"
                                    class="material-icons-outlined clickable-icon"
                                    (click)="removeTransformation(i)"
                                    matTooltip="Delete"
                                >delete</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-template #loading>
        <mat-dialog-content class="loading">
            <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
            ></ngx-skeleton-loader>
        </mat-dialog-content>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>

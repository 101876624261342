import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { EnvironmentService } from '@app/core/services/environment.service';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class ToilBalanceReportService {

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private overlayService: OverlayService,
        private datePipe: DatePipe,
        private envService: EnvironmentService
    ) {
    }

    public fetch(state: State, filterString: string, sortString: string, showOverlay?: boolean,
                 filterInactiveEmployees?: boolean): Observable<GridDataResult> {
        let params = new HttpParams();
        state.skip ? params = params.append('skip', state.skip.toString()) : null;
        state.take ? params = params.append('take', state.take.toString()) : null;
        filterString ? params = params.append('Filter', filterString) : null;

        sortString ? params = params.append('Sort', sortString) : null;
        params = params.append('FilterInactiveEmployees', filterInactiveEmployees.toString());

        if (showOverlay) {
            this.overlayService.show(this.translate.instant('FetchingData'));
        }

        return this.http.get(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${api_routes.PREDEFINED}/${api_routes.TOIL_BALANCE_REPORT}`,
            {
                headers,
                params,
            })
            .pipe(
                finalize(() => showOverlay ? this.overlayService.hide() : null),
                map(
                    (response) => {
                        return <GridDataResult> {
                            data: response['data'],
                            total: parseInt(response['total'], 10),
                        };
                    }
                )
            );
    }

    public async queryAll(st?: State, filterString?: string, sortString?: string, filterInactiveEmployees?: boolean):
        Promise<GridDataResult> {
        const state = Object.assign({}, st);

        let dataToReturn: GridDataResult = {
            data: [],
            total: null
        };

        while(dataToReturn.total === null || state.skip < dataToReturn.total) {
            const res = await this.fetch(state, filterString, sortString, true, filterInactiveEmployees).toPromise();

            if (res && res.data && res.data.length > 0) {
                dataToReturn.data = dataToReturn.data.concat(res.data);
                state.skip += state.take;
                dataToReturn.total = res.total;
            } else {
                break;
            }
        }

        return dataToReturn;
    }

}

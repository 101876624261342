import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DenialComment, EmployeeToilPolicy, EmployeeToilPolicySubmit, EmployeeToilRecord, EmployeeToilRecordSubmit, EmployeeToilRequest, TOIL, ToilClassType, ToilManualAdjustmentsSubmit, ToilPolicyCalculationLog, UpdateToilManualAdjustmentsSubmit } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import {filter, map, Observable, of} from 'rxjs';
import { EnvironmentService } from "@app/core/services/environment.service";
import * as moment from 'moment';
import { TimeOff, TimeOffEmailTranslation } from "@app/modules/lookup/models/time-off.model";
import { PagedData } from "@app/modules/lookup/models/paged-data.model";
import { ToilPolicy } from "@app/modules/lookup/models/toil-policy.model";
import { ToilListVariant } from "@app/modules/lookup/models/toil-list-variant.model";
import { AssignEmployeeTimeOffType, EmployeeTimeOffType } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model";
import { EmployeeAbsence, EmployeeAbsenceVerbose } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/models/employee-absence.model";
import { ToilExpiryPeriodUnitType } from "@app/modules/lookup/models/toil-expiry-period-unit-type.model";
import { ToilExpiryPointType } from "@app/modules/lookup/models/toil-expiry-point-type.model";
import { api_routes, routes } from '@app/consts';
import { DatePipe } from '@angular/common';
import { GetAllRequestService } from '@app/shared/services/get-all-request.service';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class TimeOffInLieuService {

    constructor(
        private http: HttpClient,
        private getAllRequestService: GetAllRequestService,
        private datePipe: DatePipe,
        private envService: EnvironmentService
    ) {
    }

    submitRequest(data: any): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests`, data, {
          headers: headers
        });
    }

    editRequest(requestId: string, requestSubmit: any): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/${requestId}`, requestSubmit, {
          headers: headers
        });
    }

    getRequest(requestId: string): Observable<TOIL> {
        // return this.http.get<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/${requestId}`, {
        //     headers: headers
        // });
        //TODO replace dummy result with time off in lieu api
        return of(this.getDummyRequests()[0]);
    }

    approveRequest(requestId: string): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/Approve/${requestId}`, {
            headers: headers
        });
        // TODO replace dummy result with time off in lieu api
        // return of({success: true, message: 'Request successful', requestId});

    }

    denyRequest(requestId: string, denialComments: DenialComment): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/Deny/${requestId}`, denialComments, {
            headers: headers
        });
        // TODO replace dummy result with time off in lieu api
        // return of({success: true, message: 'Request successful', requestId});
    }

    getPendingApproval(skip?: number, take?: number, sort?: string, filter?: string, asOf?: string) {
        return this.getAllRequestService.fetchAllData<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/AwaitingApproval`, skip, take, sort, filter, asOf)
    }

    getMyRequests(skip?: number, take?: number, sort?: string, filter?: string, asOf?: string) {
        return this.getAllRequestService.fetchAllData<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.EMPLOYEE_TOIL_REQUESTS}/MyRequests`, skip, take, sort, filter, asOf)
    }

    deleteRequest(requestId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}Toil/EmployeeToilRequests/${requestId}`, {
            headers: headers
        });
    }

    postToilType(timeOffInLieu: any): Observable<any> {
        return this.http.post<any>(this.envService.env.apiUrl + 'Toil/Types', timeOffInLieu, {
            headers: headers
        });
    }

    postToilTypeIcon(image: File, toilTypeId: string): Observable<Object> {
        const formData = new FormData();
        formData.append('file', image);
        return this.http.post(this.envService.env.apiUrl + 'Toil/Types/' + toilTypeId + '/Icon', formData);
    }

    async getAllToilTypes(sortString?: string, filterString?: string): Promise<PagedData<ToilListVariant>> {
        let take = 100;
        let skip = 0;
        let toilTypes = {
          skip: null,
          take: null,
          totalPages: null,
          total: null,
          data: []
        }
    
        while (toilTypes.total === null || skip < toilTypes.total) {
            const res = await this.getToilTypes(filterString, skip, take.toString(), sortString).toPromise();
    
            if (res && res.data && res.data.length > 0) {
              toilTypes.data = toilTypes.data.concat(res.data);
                skip += take;
                toilTypes.total = res.total;
            } else {
                break;
            }
        }
    
        return toilTypes;
    }

    getToilTypes(filter?: string, skip?: number, take?: string, sortString?: string): Observable<PagedData<ToilListVariant>> {
        filter = filter ? filter : "";
        sortString = sortString ? sortString : "";
        skip = skip ? skip : 0;
        take = take ? take : "50";
        let params = new HttpParams();
        params = params.set('filter', filter).set('skip', skip).set('take', take).set('sort', sortString);

        return this.http.get<any>(this.envService.env.apiUrl + 'Toil/Types', {
            headers: headers,
            params: params
        });
    }

    getToilTypeById(id: string): Observable<TimeOff> {
        return this.http.get<any>(this.envService.env.apiUrl + `Toil/Types/${id}`, {
            headers: headers
        });
    }

    getToilTypeIcon(toilTypeId: string): Observable<any> {
        return this.http.get(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}/Icon`, {responseType: 'text'});
    }

    updateToilType(toilTypeId: string, timeOffInLieu: any): Observable<any> {
        return this.http.put<any>(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}`, timeOffInLieu, {
            headers: headers
        });
    }

    deleteToilTypeById(id: string): Observable<TimeOff> {
        return this.http.delete<any>(this.envService.env.apiUrl + `Toil/Types/${id}`, {
            headers: headers
        });
    }

    getToilExpiryPeriodUnitTypes(): Observable<ToilExpiryPeriodUnitType[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Toil/ExpiryPeriodUnitTypes`, {
            headers: headers
        }).pipe(
            map(res => res.data)
        );
    }

    getToilExpiryPointTypes(): Observable<ToilExpiryPointType[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Toil/ExpiryPointTypes`, {
            headers: headers
        }).pipe(
            map(res => {
                let resp = [];
                res.data.forEach(toilType => {
                    //Leave this option out for now
                    if (toilType.id !== 'DatePeriodSubmitted') {
                        resp.push(toilType);
                    }
                });
                return resp;
            })
        );
    }

    createToilPolicy(toilTypeId: string, toilPolicyData: any) {
        return this.http.post<any>(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}/Policies`, toilPolicyData, {
            headers: headers
        });
        return of({success: true, message: 'Request successful', toilPolicyData});
    }

    getAllToilPolicies(id: string, skip?: number, take?: number, sort?: string, filter?: string, asOf?: string){
        return this.getAllRequestService.fetchAllData<ToilPolicy>(this.envService.env.apiUrl + `Toil/Types/${id}/Policies/`, skip, take, sort, filter, asOf)
    }

    getToilPolicies(id: string, take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ToilPolicy>> {
        let params = new HttpParams();
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(this.envService.env.apiUrl + `Toil/Types/${id}/Policies/`, {
            headers: headers,
            params: params
        });
    }

    getToilPolicyById(toilTypeId: string, policyId: string): Observable<ToilPolicy> {
        return this.http.get<any>(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}/Policies/${policyId}`, {
            headers: headers
        });
        // return of(this.generateToilPolicy());
    }

    updateToilPolicy(toilTypeId: string, toilPolicyId: string, toilPolicyData: any) {
        return this.http.put<any>(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}/Policies/${toilPolicyId}`, toilPolicyData, {
            headers: headers
        });
        // return of({success: true, message: 'Request successful', toilPolicyData});
    }

    deleteToilPolicyById(toilTypeId: string, policyId: string): Observable<TimeOff> {
        return this.http.delete<any>(this.envService.env.apiUrl + `Toil/Types/${toilTypeId}/Policies/${policyId}`, {
            headers: headers
        });
        // return of(this.generateTimeOff());
    }

    getEmployeeToilTypes(employeeId: string): Observable<EmployeeTimeOffType[]> {
        // return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TIMEOFF}/${api_routes.TYPES}`, {
        //     headers: headers
        // })
        // return this.http.get<any>(`${this.envService.env.apiUrl}TALENT/${employeeId}/Toil/TYPES`, {
        //     headers: headers
        // })
        //TODO replace dummy result with time off in lieu api
        return of([this.generateEmployeeTimeOffType()]);
    }

    getEmployeeToilRecords(employeeId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<EmployeeToilRecord>> {
        let params = new HttpParams();
        employeeId ? params = params.append('employeeId', employeeId) : null;
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.RECORDS}`, {
            headers: headers,
            params: params
        })
    }

    getEmployeeToilRecord(id: string) {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.RECORDS}/${id}`, {
            headers: headers,
        })
    }

    createEmployeeToilRecord(employeeToilRecordSubmit: EmployeeToilRecordSubmit): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.RECORDS}`, employeeToilRecordSubmit, {
            headers: headers
        });
    }

    updateEmployeeToilRecord(id: string, employeeToilRecordSubmit: EmployeeToilRecordSubmit): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.RECORDS}/${id}`, employeeToilRecordSubmit, {
            headers: headers
        });
    }

    deleteEmployeeToilRecord(id: string) {
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.RECORDS}/${id}`, {
            headers: headers,
        })
    }

    getEmployeeToilPolicies(employeeId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<EmployeeToilPolicy>> {
        let params = new HttpParams();
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(this.envService.env.apiUrl + `Talent/${employeeId}/Toil/EmployeeToilPolicies`, {
            headers: headers,
            params: params
        })
    }

    getEmployeePolicies(employeeId?: string){
        let filter = employeeId ? ("?filter=(Employee.Id = \"" + employeeId + "\"") : "";
        return this.http.get<any>(`${this.envService.env.apiUrl}Toil/EmployeesToilPolicies${filter}`, {
            headers: headers
        })
    }

    assignEmployeeToilPolicy(employeeToilPolicySubmit: EmployeeToilPolicySubmit){
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.EMPLOYEES_TOIL_POLICIES}`, employeeToilPolicySubmit, {
            headers: headers
        })
    }

    removeEmployeeToilPolicy(employeeToilPolicyId: string){
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.EMPLOYEES_TOIL_POLICIES}/${employeeToilPolicyId}`, {
            headers: headers
        })
    }

    getToilClassTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ToilClassType>> {
        let params = new HttpParams();
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.CLASS_TYPES}`, {
            headers: headers,
            params: params
        })
    }

    getManualAdjustments(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<EmployeeToilPolicy>>{
        let params = new HttpParams();
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.MANUAL_ADJUSTMENTS}`, {
            headers: headers,
            params: params
        })
    }

    getSingleManualAdjustments(id?: string){
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.MANUAL_ADJUSTMENTS}/${id}`, {
            headers: headers
        })
    }

    deleteSingleManualAdjustments(id?: string){
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.MANUAL_ADJUSTMENTS}/${id}`, {
            headers: headers
        })
    }

    updateManualAdjustments(id: string, toilManualAdjustmentsSubmit: UpdateToilManualAdjustmentsSubmit){
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}${api_routes.MANUAL_ADJUSTMENTS}/${id}`, toilManualAdjustmentsSubmit, {
            headers: headers
        })
    }

    postManualAdjustments(toilManualAdjustmentsSubmit: ToilManualAdjustmentsSubmit){
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TOIL}/${api_routes.MANUAL_ADJUSTMENTS}`, toilManualAdjustmentsSubmit, {
            headers: headers
        })
    }

    getEmployeeToilPolicyCalculationLog(employeeId: string, employeeToilPolicyId: string){
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}${api_routes.TOIL}${api_routes.EMPLOYEE_TOIL_POLICIES}/${employeeToilPolicyId}${api_routes.CALCULATION_LOGS}`, {
            headers: headers
        })
        .pipe(
            map(
              (response) => response.data
            )
          )

    }


    formatData(data: ToilPolicyCalculationLog[]): ToilPolicyCalculationLog[]{
      data.forEach( (element, index) => {
        element.on = this.datePipe.transform(element.on, 'longDate');
        element.createdOn = this.datePipe.transform(element.createdOn, 'long');
        element.expiredOn = this.datePipe.transform(element.expiredOn, 'long');
      });

      return data;
    }

    //Dummy Generators TODO delete once replaced

    generateEmployeeTimeOffType() : EmployeeTimeOffType{
        let dummyData: EmployeeTimeOffType = {
            availableHours: 0,
            timeOffType: {
                id: '',
                name: 'Test Emp Time Off Type'
            },
            timeOffPolicy: {
                id: '',
                name: 'Test TO Policy',
                carryOverAmount: 0,
                carryOverMonth: 0,
                carryOverDay: 0
            }
        };
        return dummyData;
    }

    generateEmployeeAbsence(): EmployeeAbsence{
        let dummyData: EmployeeAbsence = new EmployeeAbsence();
        dummyData.timeOffType = {id: 'testId', name: 'Test Emp Absence'};
        return dummyData;
    }

    generateEmployeeAbsenceVerbose(): EmployeeAbsenceVerbose{
        let dummyData: EmployeeAbsenceVerbose = new EmployeeAbsenceVerbose();
        let jsonString = '{"version":{"createdOn":"2023-05-10T12:51:37.9079064+00:00","effectiveDate":"2023-05-10T00:00:00","modifiedList":["StartTime","EndTime","Udl1","Hours"],"changeReason":null,"comments":"Testing","isDeleted":false,"createdBy":{"id":"usr_pI5tCwNqdyf8SB","firstName":"Mad","lastName":"Lucy"}},"employeeDisplayId":null,"id":"cas_Uuh4prhMogHTM6","startDate":"2023-05-03T00:00:00","endDate":"2023-05-10T00:00:00","startTime":"08:00:00","endTime":"17:00:00","timeOffType":{"id":"tot_gDBvKITfODrjEC","name":"Compensatory Time"},"absenceNumber":null,"comments":null,"returnToWork":null,"udl1":{"id":"lok_rmD25D3ZWJyJU5","text":"Covid"},"hours":8}';
        dummyData = JSON.parse(jsonString);
        return dummyData;
    }

    getDummyRequests(): TOIL[] {
        return [
            {
                id: "123456",
                employee: {
                    id: "987654",
                    firstName: "John",
                    lastName: "Doe"
                },
                toilPolicy: {
                    id: "789",
                    name: "Policy A"
                },
                toilClassType: {
                    id: "456",
                    name: "Dummy A"
                },
                startTime: "09:00 AM",
                endTime: "05:00 PM",
                startDate: "2023-06-01",
                endDate: "2023-06-01",
                halfDay: false,
                hours: 8,
                comments: "Worked overtime",
                isApproved: true,
                approvedBy: {
                    id: "246",
                    firstName: "Jane",
                    lastName: "Smith"
                },
                approvedOn: "2023-06-02",
                deniedBy: {
                    id: "",
                    firstName: "",
                    lastName: ""
                },
                denialComments: "",
                createdBy: {
                    id: "135",
                    firstName: "Adam",
                    lastName: "Johnson"
                },
                createdOn: "2023-06-01"
            },
            {
                id: "789012",
                employee: {
                    id: "654321",
                    firstName: "Sarah",
                    lastName: "Williams"
                },
                toilPolicy: {
                    id: "321",
                    name: "Policy B"
                },
                toilClassType: {
                    id: "654",
                    name: "Dummy B"
                },
                startTime: "08:30 AM",
                endTime: "12:30 PM",
                startDate: "2023-06-15",
                endDate: "2023-06-15",
                halfDay: true,
                hours: 4,
                comments: "Attended training",
                isApproved: false,
                approvedBy: {
                    id: "",
                    firstName: "",
                    lastName: ""
                },
                approvedOn: "",
                deniedBy: {
                    id: "789",
                    firstName: "Mark",
                    lastName: "Davis"
                },
                denialComments: "Training not approved",
                createdBy: {
                    id: "246",
                    firstName: "Jane",
                    lastName: "Smith"
                },
                createdOn: "2023-06-15"
            }
        ];
    }

    getEmailTranslations(translationId: string): Observable<TimeOffEmailTranslation> {
        return this.http.get<any>(this.envService.env.apiUrl + `Toil/Emails/${translationId}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
    
    updateEmailTranslation(translationId: string, translationData: TimeOffEmailTranslation) {
    return this.http.put<any>(this.envService.env.apiUrl + `Toil/Emails/${translationId}`, translationData, {
        headers: {
        'Content-Type': 'application/json'
        }
    });
    }
}

<div class="integration-tab-container">
    <div>
        <div class="form-section-title"><h6>{{ integrationSettings.integration.name }}</h6></div>
    </div>

    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab [label]="translate.instant('Integrations-Details')">
            <app-icims-integration-details-tab 
                *ngIf="integrationSettings.integration.id === 'iCIMS' || integrationSettings.integration.id === 'IcimsInbound'"
                [integrationId]="integrationSettings.id"
            ></app-icims-integration-details-tab>
            <app-payworks-integration-details 
                *ngIf="integrationSettings.integration.id === 'Payworks'"
                [integrationId]="integrationSettings.id"
            ></app-payworks-integration-details>
        </mat-tab>
        <mat-tab [label]="translate.instant('Integrations-Mappings')" *ngIf="integrationSettings.integration.id !== 'IcimsInbound'">
            <app-integration-mappings-tab *ngIf="integrationSettings.integration.id === 'iCIMS'" [integrationId]="integrationSettings.id"></app-integration-mappings-tab>
            <app-payworks-mappings *ngIf="integrationSettings.integration.id === 'Payworks'" [integrationId]="integrationSettings.id"></app-payworks-mappings>
        </mat-tab>
        <mat-tab [label]="translate.instant('Integrations-Logs')">
            <app-integration-logs-tab [integrationId]="integrationSettings.integration.id"></app-integration-logs-tab>
        </mat-tab>
        <mat-tab [label]="translate.instant('Integrations-TestConnection')">
            <app-payworks-test-connection 
                [integrationId]="integrationSettings.id" 
                [integrationType]="integrationSettings.integration.id"
            ></app-payworks-test-connection>
        </mat-tab>
    </mat-tab-group>
</div>

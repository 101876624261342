import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable } from 'rxjs';
import { EmployeeDiscipline, EmployeeDisciplineSubmit, EmployeeDisciplineVerbose } from '../models/employee-disciplines.model';
import { api_routes } from '@app/consts';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});


@Injectable({
  providedIn: 'root'
})
export class EmployeeDisciplinesService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }
  
  getEmployeeDisciplines(id: string, take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<EmployeeDiscipline>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${id}/${api_routes.DISCIPLINE}`, {
      headers: headers,
      params: params
    });
  }

  getEmployeeDiscipline(employeeId: string, disciplineId: string): Observable<EmployeeDisciplineVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DISCIPLINE}/${disciplineId}`, {
      headers: headers
    });
  }

  deleteEmployeeDiscipline(employeeId: string, disciplineId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DISCIPLINE}/${disciplineId}`, {
      headers: headers
    });
  }

  postEmployeeDiscipline(employeeId: string, discipline: EmployeeDisciplineSubmit): Observable<EmployeeDiscipline> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DISCIPLINE}`, discipline, {
      headers: headers
    });
  }

  updateEmployeeDiscipline(employeeId: string, disciplineId: string, discipline: EmployeeDisciplineSubmit): Observable<EmployeeDiscipline> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DISCIPLINE}/${disciplineId}`, discipline, {
      headers: headers
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-localization-text-area-form-field',
  templateUrl: './localization-text-area-form-field.component.html',
  styleUrls: ['./localization-text-area-form-field.component.scss']
})
export class LocalizationTextAreaFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() currentCulture: Culture;
  @Input() cultures: Culture[] = [];

  @Output() emitAddtoFormArray: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitDeleteFromFormArray: EventEmitter<{index: number, formControl: string}> = new EventEmitter<{index: number, formControl: string}>();

  isLoading: boolean;
  user$: any;
  isExpanded = false;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  isSpecialUrl(): boolean {
    const url = window.location.href;
    return url.includes('qa.hubbubhr.com') || url.includes('sightsavers-sandbox.hubbubhr.com') || url.includes('sightsavers.hubbubhr.com');
  }

  isEnGbCulture(culture: string | any): boolean {
    return culture === 'en-GB' || culture?.id === 'en-GB';
  }

  addNewLocalization() {
    this.emitAddtoFormArray.emit(this.formElement.formControl);
  }

  deleteLocalization(index: number) {
    this.emitDeleteFromFormArray.emit(
      {
        index: index,
        formControl: this.formElement.formControl
      }
    );
  }

  toggleDiv() {
    this.isExpanded = !this.isExpanded;
  }

}

<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="Integrations-Event-Details">{{ 'Integrations-Event-Details' | translate: {Default: "Integration Event Details"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="isLoading; else loaded">
        <mat-dialog-content class="loading">
            <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
            ></ngx-skeleton-loader>
        </mat-dialog-content>
    </ng-container>
    <ng-template #loaded>
        <div class="container">
            <div class="details">
                <div>
                    <div appLocalizationPopupDirective localizationCode="Integration" class="label">{{ 'Integration' | translate: {Default: "Integration"} }}</div>
                    <div class="value">{{ integrationEventVerbose?.integration?.name }}</div>
                </div>

                <div>
                    <div appLocalizationPopupDirective localizationCode="Integrations-PayloadReceived" class="label">{{ 'Integrations-PayloadReceived' | translate: {Default: "Payload Received"} }}</div>
                    <div class="value">{{ integrationEventVerbose?.timestamp | date: 'medium' }}</div>
                </div>

                <div>
                    <div appLocalizationPopupDirective localizationCode="Integrations-PayloadProcessed" class="label">{{ 'Integrations-PayloadProcessed' | translate: {Default: "Payload Processed"} }}</div>
                    <div class="value">{{ integrationEventVerbose?.processedTimestamp | date: 'medium' }}</div>
                </div>

                <div>
                    <div appLocalizationPopupDirective localizationCode="Integrations-Processed'" class="label">{{ 'Integrations-Processed' | translate: {Default: "Processed'"} }}</div>
                    <div class="value">
                        <mat-checkbox setColor="primaryColour" [checked]="integrationEventVerbose?.processed"></mat-checkbox>
                    </div>
                </div>

                <div>
                    <div appLocalizationPopupDirective localizationCode="Integrations-Successful" class="label">{{ 'Integrations-Successful' | translate: {Default: "Successful"} }}</div>
                    <div class="value">
                        <mat-chip 
                            *ngIf="integrationEventVerbose?.successful !== null"
                            [ngClass]="
                                integrationEventVerbose?.successful === true ? 'green-chip' : 
                                integrationEventVerbose?.successful === false ? 'red-chip' :
                                ''
                            "
                        >
                            <div appLocalizationPopupDirective [localizationCode]="integrationEventVerbose?.successful === true ? 'Successful' : 'Unsuccessful'">
                                {{ integrationEventVerbose?.successful === true ? 'Successful' : 'Unsuccessful' }}
                            </div>
                        </mat-chip>
                    </div>
                </div>
                
                <div>
                    <div appLocalizationPopupDirective localizationCode="Integrations-AffectedUser" class="label">{{ 'Integration-AffectedUser' | translate: {Default: "Affected User"} }}</div>
                    <div class="value">
                        <div class="employee-name" *ngIf="integrationEventVerbose?.affectedUser !== null">
                            <app-employee-image [employeeId]="integrationEventVerbose?.affectedUser?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                
                            <span class="employee">
                                {{integrationEventVerbose?.affectedUser?.firstName}} {{integrationEventVerbose?.affectedUser?.lastName}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective localizationCode="Integrations-ViewPayload">
                            {{ 'Integrations-ViewPayload' | translate: {Default: "View Payload"} }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <pre>{{ getPrettyJson(integrationEvent.payload) }}</pre>
                </mat-expansion-panel>
        
                <!-- Add more expansion panels here for logs if needed -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective localizationCode="Integrations-ViewLogs">
                            {{ 'Integrations-ViewLogs' | translate: {Default: "View Logs"} }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
        
                    <pre>{{ mergedMessages }}</pre>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective localizationCode="Integrations-Results">
                            {{ 'Integrations-Results' | translate: {Default: "Results"} }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
        
                    <pre>{{ integrationEventVerbose.results }}</pre>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </ng-template>

</mat-dialog-content>


<mat-dialog-actions>
    <button 
        setColor="primaryColour" 
        color="primary" 
        class="mat-raised-button" 
        (click)="close()"
    >{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Form } from '../models/form.model';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormsComponent extends SecurityProtectedBase implements OnInit {
    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType: String = 'array';
    public gridData: any;
    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;
    sortString: string = "name-asc";

    private getRequest: any;
    public searchValue: string;
    public searchFilterString: string;
    public columns: any[] = [
        {field: 'name', title: 'Name', dataType: "String"},
        { field: "table", subField: "name", title: "Table", dataType: "String"},
    ];
    clearSelectedItems: boolean = false;

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private translate: TranslateService,
        public router: Router,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private metadataFormService: MetadataFormService,
        featureService: FeatureService
    ){
        super(featureService, features.FORMS);
    }

    ngOnInit(): void {
        this.getForms();
    }

    getForms(): void {
        this.isLoading = true;

        this.getRequest = this.metadataFormService.getForms(this.skip, String(this.pageSize), this.searchFilterString, this.sortString)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getForms();
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;

        if (sort[0].dir === undefined) {
            this.sortString = null;
        } else {
            //use regex to get column field to sort with
            let field: any;

            //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
            (sort[0].field).match(/.+(?=\.)/) === null
                ? field = sort[0].field
                : field = (sort[0].field).match(/.+(?=\.)/);
            this.sortString = `${field}-${sort[0].dir}`;
        }

        this.getForms();
    }


    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(selectedItem => defer(() => this.metadataFormService.deleteForm(selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.getForms();
                    this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
                }
            );
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.searchFilterString = `(Name like "${this.searchValue}") OR (id like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getForms();
    }

    navigateToForm(form?: Form) {
        if(form){
          this.router.navigate([`${routes.FORM}${routes.EDIT}/${form?.id}`], {relativeTo: this.route});
        }
        else {
          this.router.navigate([`${routes.CREATE_FORM}`], {relativeTo: this.route});
        }
    }

}

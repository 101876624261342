import { Injectable } from '@angular/core';
import { GridState } from './grid-state.model';

@Injectable({
  providedIn: 'root'
})
export class GridStateService {
  private gridStates: { [gridId: string]: any } = {};

  // Get the state for a specific grid
  getState(gridId: string): GridState {
    return this.gridStates[gridId] || {
      pristine: true,
      skip: 0,
      pageSize: 20,
      sort: '',
      sortDescriptors: [],
      filter: '',
      filters: [],
      filterToggleDetails: null,
      searchValue: null
    };
  }

  // Set the state for a specific grid
  setState(gridId: string, state: any): void {
    this.gridStates[gridId] = state;
  }

  // Clear the state for a specific grid
  clearState(gridId: string): void {
    delete this.gridStates[gridId];
  }
}

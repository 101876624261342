import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'employeeNavlistSearch'
})
export class EmployeeNavlistSearchPipe implements PipeTransform {

    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;

        searchText = searchText.toLowerCase();

        return items.filter(item => {
            return item.text.toLowerCase().includes(searchText);
        });
    }
}
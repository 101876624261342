<div class="form-section">
    <div class="form-section-title">
        <h6>{{ formElement.text }}</h6>
    </div>
    <div class="form-section-contents">
        <div class="section-row">
            <div [formGroup]="parentGroup" class="container" [ngClass]="{'expanded': isExpanded, 'collapsed': !isExpanded}">
                <div [formArrayName]="formElement.formControl">
                    <div class="top-row" formGroupName="0">
                        <div class="language-dropdown">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    
                                </mat-label>
            
                                <mat-select disableOptionCentering formControlName="culture">
                                    <mat-select-trigger>
                                        <img class="flag-icon" 
                                            [src]="
                                                isSpecialUrl() &&  isEnGbCulture(parentGroup.get(formElement.formControl)['controls'][0].get('culture').value) ? 
                                                'assets/icons/world-icon.png' : 
                                                'assets/country-flags/svg/' + parentGroup.get(formElement.formControl)['controls'][0].get('culture').value + '.svg'
                                            ">                   
                                    </mat-select-trigger>
            
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let culture of cultures" [value]="culture.id">
                                        <img class="flag-icon" 
                                            [src]="isSpecialUrl() && isEnGbCulture(culture) ? 'assets/icons/world-icon.png' : 'assets/country-flags/svg/' + culture.id + '.svg'">                   
                                        {{culture.text}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
            
                        <div class="text-input">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ formElement.text }}
                                </mat-label>
                
                                <textarea
                                    matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="8"
                                    cdkAutosizeMaxRows="8"
                                    [formControlName]="formElement.localizationValueParam"
                                    placeholder="{{ formElement.text }}">
                                </textarea>
                            </mat-form-field>
                        </div>
            
                        <mat-icon 
                            class="clickable-icon material-icons-outlined" 
                            [matTooltip]="isExpanded ? translate.instant('HideLocalizations') : translate.instant('ShowLocalizations')" 
                            (click)="toggleDiv()"
                        >language</mat-icon>
                    </div>
            
                    <div>
                        <div class="localization-row" *ngFor="let localizationForm of parentGroup.get(formElement.formControl)['controls']; let i = index">
                
                            <ng-container *ngIf="i !== 0">
                                <div class="left" [formGroupName]="i">
                                    <div class="language-dropdown">
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                <!-- {{ 'Language' | translate: {Default: "Language"} }} -->
                                            </mat-label>
                        
                                            <mat-select disableOptionCentering formControlName="culture">
                                                <mat-select-trigger>
                                                    <img class="flag-icon" src="assets/country-flags/svg/{{localizationForm.get('culture').value}}.svg">
                                                </mat-select-trigger>
                
                                                <mat-option></mat-option>
                                                <mat-option *ngFor="let culture of cultures" [value]="culture.id">                                
                                                    <img class="flag-icon" src="assets/country-flags/svg/{{culture.id}}.svg">
                                                    {{culture.text}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
            
                                    <div class="text-input">
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                {{ formElement.text }}
                                            </mat-label>
            
                                            <textarea
                                                matInput
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="8"
                                                cdkAutosizeMaxRows="30"
                                                [formControlName]="formElement.localizationValueParam"
                                                placeholder="{{ formElement.text }}">
                                            </textarea>
                                        </mat-form-field>
                                    </div>
                    
                                </div>
                                <mat-icon 
                                    setColor 
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                    ]"
                                    class="material-icons-outlined clickable-icon" 
                                    *ngIf="formElement.disabled !== 'true'" 
                                    (click)="deleteLocalization(i)" 
                                    matTooltip="Delete"
                                >delete</mat-icon>
                            </ng-container>
                        </div>
                        <button mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewLocalization()" *ngIf="formElement.disabled !== 'true'">
                            <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddLocalization' | translate: {Default: "Add Localization"} }}
                        </button>
                    </div>
            
                </div>
            
            
            </div>
        </div>
    </div>
</div>




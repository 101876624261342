import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EmployeeDisciplinesService } from './services/employee-disciplines.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ActivatedRoute } from '@angular/router';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { EmployeeDiscipline, EmployeeDisciplineVerbose } from './models/employee-disciplines.model';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-disciplines',
  templateUrl: './employee-disciplines.component.html',
  styleUrls: ['./employee-disciplines.component.scss']
})
export class EmployeeDisciplinesComponent implements OnInit {
  employeeID: string;

  public columns: any[] = [
    { field: "date", title: "Date", type: "date", tableId: "tfi_DisciplineDate" },
    { field: "action", subField: "text", title: "Action", tableId: "tfi_DisciplineAction" },
    { field: "reason", subField: "text", title: "Reason", tableId: "tfi_DisciplineReason" },
    { field: "comments", title: "Comments", tableId: "tfi_DisciplineComments" },
    { field: "conductedBy", title: "Conducted By", type: "employee", tableId: "tfi_DisciplineConductedBy" },
  ];
  filterCategories: any[] = [
    { field: "date", title: "Date", dataType: 'Date', tableId: "tfi_DisciplineDate" },
    { field: "action.text", dataType: 'String', title: "Action", tableId: "tfi_DisciplineAction" },
    { field: "reason.text", dataType: 'String', title: "Reason", tableId: "tfi_DisciplineReason" },
    { field: "comments", title: "Comments", dataType: 'String', tableId: "tfi_DisciplineComments" },
    { field: "ConductedBy.FirstName", dataType: 'String', title: "Conducted By", tableId: "tfi_DisciplineConductedBy" },
  ];
  sortableColumns: any[] = [
    { field: "date", sortValue: "date" },
    { field: "action", sortValue: "action.text" },
    { field: "reason", sortValue: "reason.text" },
    { field: "comments", sortValue: "comments" },
    { field: "conductedBy", sortValue: ["conductedBy.Firstname", "conductedBy.Lastname"] },
  ]

  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_34F2s6qVc4fT2M'
  changeReasonFormId: string = 'frm_I4jjtthFWVf2rW';
  formValid: boolean;
  private sortString: string = null;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.DISCIPLINE}/[entityId]`;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private employeeDisciplinesService: EmployeeDisciplinesService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
    private overlayService: OverlayService
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getDisciplines();
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getDisciplines() {
    this.isLoading = true;

    this.employeeDisciplinesService.getEmployeeDisciplines(this.employeeID, this.pageSize, this.skip, this.sortString, this.filterString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  getDiscipline(discipline?: EmployeeDiscipline) {
    if(discipline === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.employeeDisciplinesService.getEmployeeDiscipline(this.employeeID, discipline.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(discipline?: EmployeeDisciplineVerbose) {
    let formData = {
      id: discipline ? discipline.id : null,
      originalCreatedOn: discipline ? discipline.version?.createdOn : null,
      asof: discipline ? discipline.version?.versionEffectiveDate : null,
      date: discipline ? discipline.date : null,
      reason: discipline ? discipline.reason?.id : null,
      action: discipline ? discipline.action?.id : null,
      comments: discipline ? discipline.comments : null,
      conductedBy: discipline ? discipline.conductedBy?.id : null,
      changeReason: '',
      changeReasonComments: ''
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('Discipline')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.employeeDisciplinesService.postEmployeeDiscipline(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getDisciplines();
        }
      );
    }
    else {
      this.employeeDisciplinesService.updateEmployeeDiscipline(this.employeeID, formData.id, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getDisciplines();
        }
      );
    }
  }

  deleteDisciplines(DisciplineIds: string[]) {
    const observables = DisciplineIds.map(selectedItem => defer(() => this.employeeDisciplinesService.deleteEmployeeDiscipline(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getDisciplines();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getDisciplines();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getDisciplines();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;
    this.getDisciplines();
  }

}

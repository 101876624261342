<app-site-settings-menu>
    <mat-card *ngIf="hasFeatureAccess">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="OrganizationStructure">{{ 'OrganizationStructure' | translate: {Default: "Organization Structure"} }}</h5>
            <div>
                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="redirectOrgChart()" class="k-mr-3" *appHasFeature="features.ORG_REPORT">
                    {{ 'ShowOrganizationChart' | translate: {Default: "Show Organization Chart"} }}
                </button>
                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="showOrganizationTypesModal()" class="k-mr-3">
                    {{ 'ManageOrganizationTypes' | translate: {Default: "Manage Organization Types"} }}
                </button> 
                <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openNewOrganizationDialog()">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
                    {{ 'CreateNewOrganization' | translate: {Default: "Create New Organization"} }}
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <app-data-grid-comp
                *ngIf="hasFeatureAccess"
                [gridId]="gridId"
                [columns]="columns"
                [filterCategories]="filterCategories"
                [sort]="sort"
                [sortableColumns]="sortableColumns"
                [sortable]="{
                    mode: 'multiple'
                }"
                [bindingType]="bindingType"
                [view]="view"
                [gridData]="gridData"
                [gridDataResult]="gridDataResult"
                [isLoading]="isLoading"
                [pageSize]="pageSize"
                [skip]="skip"
                [rowSelectionSettings]="{
                    canSelectRows: true,
                    selectableMode: 'multiple',
                    selectRowBy: 'id'
                }"
                clickableRows="true"
                searchable="true"
                hideActionButtons="true"
                [isLoading]="isLoading"
                [clearSelectedItems]="clearSelectedItems"
                [filterToggleDetails]="filterToggleDetails"
                (emitOpenDialogEvent)="navigateToOrganization($event)"
                (emitDeleteEvent)="deleteAllSelected($event)"
                (emitGridStateChangeEvent)="gridStateChange($event)"
            ></app-data-grid-comp>
        </mat-card-content>
    </mat-card>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>

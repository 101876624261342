import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffListVariant } from '@app/modules/lookup/models/time-off-list-variant.model';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { EmployeeTimeOffType } from '../../models/leave-request.model';
import { EmployeeLeaveService } from '../../services/employee-leave.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-assign-employee-policy-dialog',
  templateUrl: './assign-employee-policy-dialog.component.html',
  styleUrls: ['./assign-employee-policy-dialog.component.scss']
})
export class AssignEmployeePolicyDialogComponent implements OnInit {
  timeOffTypeOptions: TimeOffListVariant[] = [];
  selectedTimeOffType: TimeOffListVariant;
  form: UntypedFormGroup;
  employeeId: string;
  employeeTimeOffType: EmployeeTimeOffType;

  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private timeOffService: TimeOffService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<AssignEmployeePolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.employeeId = data.employeeId;
      this.employeeTimeOffType  = data.employeeTimeOffType;
  }

  ngOnInit(): void {
    this.getTimeOffTypes();
    this.createForm();
  }

  getTimeOffTypes() {
    from(this.timeOffService.getAllTimeOffTypes('name-asc'))
    .subscribe(
      res => {
        this.timeOffTypeOptions = res.data;
        this.timeOffTypeOptions.forEach(option => {
          option.policies.sort((a, b) => a.name.localeCompare(b.name));
        });
        this.selectedTimeOffType = this.timeOffTypeOptions.find( item => item.id === this.employeeTimeOffType?.timeOffType.id );
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      type: [
        {
          value: this.employeeTimeOffType ? this.employeeTimeOffType.timeOffType.id : null,
          disabled: (this.employeeTimeOffType !== undefined ? true : false)
        }, Validators.required
      ],
      policy: [null, Validators.required],
      asOf: [moment().format()],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  setSelectedTimeOffType(event: MatSelectChange) {
    this.selectedTimeOffType = this.timeOffTypeOptions.find( item => item.id === event.value );
  }

  save(){
    this.overlayService.show();

    this.employeeLeaveService.assignEmployeeTimeOffTypes(this.employeeId, [this.form.getRawValue()])
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        let message = this.employeeTimeOffType ? this.translate.instant('SavedSuccessfully') : this.translate.instant('CreatedSuccessfully');
        this.snackbarService.openSnackBar(message, 'clear', 'success');
        this.dialogRef.close(true);
      }
    )
  }

  close(){
    this.dialogRef.close();
  }

}

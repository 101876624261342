<app-site-settings-menu>
    <app-data-grid-comp
        *ngIf="hasFeatureAccess"
        [gridId]="gridId"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [bindingType]="bindingType"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="StatutoryHolidays"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        [sortableColumns]="sortableColumns"
        [sortable]="{
            mode: 'multiple'
        }"
        clickableRows="true"
        searchable="true"
        [clearSelectedItems]="clearSelectedItems"
        (emitOpenDialogEvent)="navigateToWorkRotation($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitGridStateChangeEvent)="gridStateChange($event)"
    ></app-data-grid-comp>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { VisasAndPermitsService } from './services/visas-and-permits.service';
import { VisaPermit } from './models/visa-permit.model';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-visas-and-permits',
  templateUrl: './employee-visas-and-permits.component.html',
  styleUrls: ['./employee-visas-and-permits.component.scss']
})
export class EmployeeVisasAndPermitsComponent implements OnInit {
  @Input() formIds: string[];
  employeeID: string;

  public columns: any[] = [
    { field: "documentNumber", title: "document Number", type: "navigation", tableId: "tfi_VpDn" },
    { field: "visaDocumentType", subField: "text", title: "visa Document Type", tableId: "tfi_VpVisaDocumentType" },
    { field: "issueDate", title: "issue Date", type: "date", tableId: "tfi_VpIssuedate" },
    { field: "dateExpired", title: "date expired", type: "date", tableId: "tfi_VpDateExpired" },
    { field: "currentStatus", subField: "text", title: "current Status", tableId: "tfi_VpCurrentStatus" },
    { field: "issuingCountry", subField: "name", title: "issuing Country", tableId: "tfi_IC" },
    { field: "placeOfIssue", title: "place Of Issue", tableId: "tfi_VpPlaceOfIssue" },
    { field: "travelClass", subField: "text", title: "travel Class", tableId: "tfi_VpTravelClass" },
    { field: "nameOfHolder", title: "name Of Holder", tableId: "tfi_VpNameOfHolder"  },
    { field: "relationshipToEmployee", subField: "text", title: "relationship To Employee", tableId: "tfi_VpRelationshipToEmployee" },
    { field: "humanResourceRep", title: "human Resource Rep", tableId: "tfi_VpHumanResourceRep" },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_cmkJ41CY8YWl0R'
  changeReasonFormId: string = 'frm_9fek2Ne1XWd55b';
  formValid: boolean;
  private sortString: string = 'issueDate-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.VISAS_PERMITS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private visasAndPermitsService: VisasAndPermitsService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.getVisaPermits();
    this.getTargetEmployeeTablePermissions();
  }

  getVisaPermits() {
    this.isLoading = true;

    this.visasAndPermitsService.getVisaPermits(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getVisaPermit(visaPermit?: VisaPermit) {
    if(visaPermit === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.visasAndPermitsService.getVisaPermit(this.employeeID, visaPermit.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(visaPermit?: VisaPermit) {
    let formData = {
      id: visaPermit ? visaPermit.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      issueDate: visaPermit ? visaPermit.issueDate : null,
      documentNumber: visaPermit ? visaPermit.documentNumber : null,
      humanResourceRep: visaPermit ? visaPermit.humanResourceRep : null,
      placeOfIssue: visaPermit ? visaPermit.placeOfIssue : null,
      dateExpired: visaPermit ? visaPermit.dateExpired : null,
      nameOfHolder: visaPermit ? visaPermit.nameOfHolder : null,
      visaDocumentType: visaPermit ? visaPermit.visaDocumentType?.id : null,
      issuingCountry: visaPermit ? visaPermit.issuingCountry?.id : null,
      relationshipToEmployee: visaPermit ? visaPermit.relationshipToEmployee?.id : null,
      currentStatus: visaPermit ? visaPermit.currentStatus?.id : null,
      travelClass: visaPermit ? visaPermit.travelClass?.id : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('Visa&Permit')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    if (formData.issueDate) {formData.issueDate = moment(formData.issueDate).format().slice(0, 10);}
    if (formData.dateExpired) {formData.dateExpired = moment(formData.dateExpired).format().slice(0, 10);}
    if(!formData.id){
      this.visasAndPermitsService.createVisaPermit(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getVisaPermits();
        }
      );
    }
    else {
      this.visasAndPermitsService.updateVisaPermit(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getVisaPermits();
        }
      );
    }
  }

  deleteVisaPermits(visaPermitIds: string[]) {
    const observables = visaPermitIds.map(selectedItem => defer(() => this.visasAndPermitsService.deleteVisaPermit(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getVisaPermits();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getVisaPermits();
  }

}







<app-data-grid-comp
    [columns]="columns"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="tbl_IntegrationEmployees"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [tableId]="db_tables.INTEGRATION_MAPPINGS"
    [clearSelectedItems]="clearSelectedItems"
    [parentModelId]="employeeID"
    [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="getIntegrationMapping($event)"
    (emitDeleteEvent)="deleteIntegrationMappings($event)"
></app-data-grid-comp>

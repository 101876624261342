import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { api_routes } from '@app/consts';
import { catchError, map } from 'rxjs/operators';
import { EmployeeAbsence, EmployeeAbsenceVerbose, ScheduleAbsence } from '../models/employee-absence.model';
import { DatePipe } from '@angular/common';
import { EnvironmentService } from "@app/core/services/environment.service";
import { PagedData } from '@app/modules/lookup/models/paged-data.model';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeAbsenceService {

  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getEmployeeAbsences(employeeId: string, take?: string, skip?: number, sortString?: string, filterString?: string): Observable<PagedData<EmployeeAbsence>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterString ? params = params.append('Filter', filterString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.ABSENCES}`, {
      headers: headers,
      params: params
    })
  }

  formatData(data: any) {

    data.forEach(element => {
      if(element?.endDate) {
        element.endDate = this.datePipe.transform(element.endDate, 'longDate');
      }
      if(element?.startDate) {
        element.startDate = this.datePipe.transform(element.startDate, 'longDate');
      }
      if(element?.timeOffType?.name) {
        element.timeOffType = element.timeOffType.name;
      }
      if(element?.udl1?.text) {
        element.udl1 = element.udl1.text;
      }
    });

    return data;
  }

  getEmployeeAbsence(employeeId: string, employeeAbsenceId: string): Observable<EmployeeAbsenceVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.ABSENCES}/${employeeAbsenceId}`, {
      headers: headers
    });
  }

  deleteEmployeeAbsence(employeeId: string, employeeAbsenceId: string) {
    return this.http.delete<any>(
      `${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.ABSENCES}/${employeeAbsenceId}`, {
      headers: headers
    });
  }

  createNewEmployeeAbsence(employeeId: string, employeeAbsenceSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.ABSENCES}`, employeeAbsenceSubmit, {
      headers: headers
    });
  }

  updateEmployeeAbsence(employeeId: string, employeeAbsenceSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.ABSENCES}/${employeeAbsenceSubmit.id}`, employeeAbsenceSubmit, {
      headers: headers
    });
  }

  getCurrentEmployeesAbsences(from: string, to: string, take?: string, skip?: number, sortString?: string, filterString?: string): Observable<PagedData<ScheduleAbsence>> {
    let params = new HttpParams();
    from ? params = params.append('from', from) : null;
    to ? params = params.append('to', to) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterString ? params = params.append('Filter', filterString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.ABSENCES}/${api_routes.MY_ABSENCES}`, {
      params: params,
      headers: headers,
    });
  }
}

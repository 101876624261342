import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Holiday, HolidayGroup, HolidayGroupSubmit, HolidayGroupVerbose, HolidaySubmit, HolidayVerbose } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { EnvironmentService } from "@app/core/services/environment.service";
import { api_routes } from '@app/consts';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class StatutoryHolidaysService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getHolidayGroups(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<HolidayGroup>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take !== null ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TIMEOFF}/${api_routes.STATUTORY_HOLIDAYS}/${api_routes.HOLIDAY_GROUPS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllHolidayGroups(sort?: string, filter?: string): Promise<PagedData<HolidayGroup>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
      const res = await this.getHolidayGroups(take, skip, sort, filter).toPromise();

      if (res && res.data && res.data.length > 0) {
        allData.data = allData.data.concat(res.data);
        skip += take;
        allData.total = res.total;
      } else {
        break;
      }
    }

    return allData;
  }

  getHolidayGroup(holidayGroupId: string): Observable<HolidayGroupVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, {
      headers: headers,
    });
  }

  postHolidayGroup(newHolidayGroup: HolidayGroupSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups`, newHolidayGroup, {
      headers: headers,
    });
  }

  putHolidayGroup(holidayGroupId: string, updatedHolidayGroup: HolidayGroupSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, updatedHolidayGroup, {
      headers: headers,
    });
  }

  deleteHolidayGroup(holidayGroupId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, {
      headers: headers,
    });
  }

  getHolidayDays(skip?: number, take?: string, sort?: string, filter?: string, asOf?: string): Observable<PagedData<Holiday>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days`, {
      headers: headers,
      params: params
    });
  }

  getHolidayDay(holidayGroupId: string): Observable<HolidayVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayGroupId}`, {
      headers: headers,
    });
  }

  postHolidayDay(newHolidayDay: HolidaySubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days`, newHolidayDay, {
      headers: headers,
    });
  }

  putHolidayDay(holidayId: string, updateHolidayDay: HolidaySubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayId}`, updateHolidayDay, {
      headers: headers,
    });
  }

  deleteHolidayDay(holidayDayId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayDayId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { ExcelExportEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { filter, finalize, Observable } from 'rxjs';
import { EmployeeTimeOffType, TimeOffTypeLog } from '../../models/leave-request.model';
import { EmployeeLeaveService } from '../../services/employee-leave.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-time-off-calculation-log-dialog',
  templateUrl: './time-off-calculation-log-dialog.component.html',
  styleUrls: ['./time-off-calculation-log-dialog.component.scss']
})
export class TimeOffCalculationLogDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "createdOn", title: this.translate.instant("TimeOffCalculationLog-CreatedOn"), type: 'dateTime'},
    { field: "on", title: this.translate.instant("TimeOffCalculationLog-On"), type: 'date'},
    { field: "adjustment", title: this.translate.instant("TimeOffCalculationLog-Adjustment"), type: 'decimal'},
    { field: "runningTotal", title: this.translate.instant("TimeOffCalculationLog-RunningTotal"), type: 'decimal'},
    { field: "reason", title: this.translate.instant("TimeOffCalculationLog-Reason")},
  ];
  public sort: SortDescriptor[] = [{ field: "on", dir: "desc" }];

  employeeId: string;
  employeeTimeOffType: EmployeeTimeOffType;
  isLoading: boolean;
  timeOffCalculationLog: TimeOffTypeLog[];
  pageSettings = {
    pageSizes: [5, 10, 20, 50, 100],
  };
  pageSize: number = 5;
  skip: number = 0;
  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'DisplayFutureLog',
    checked: false
  }

  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private sanitiseGridDataService: SanitiseGridDataService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<TimeOffCalculationLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.employeeId = data.employeeId;
    this.employeeTimeOffType  = data.employeeTimeOffType;
  }

  ngOnInit(): void {
    this.getCalculationLog();
  }

  getCalculationLog() {
    this.isLoading = true;
    
    this.employeeLeaveService.getCalculationLog(this.employeeId, this.employeeTimeOffType.timeOffType.id)
    .pipe(
      finalize(() => {
          this.isLoading = false;
      })
    )
    .subscribe(
      res => {
        if(this.filterToggleDetails.checked === false) {
          this.timeOffCalculationLog = res.filter(timeOffTypeLogItem => moment(timeOffTypeLogItem.on).isBefore(moment()));
        }
        else {
          this.timeOffCalculationLog = res;
        }
      }
    );
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

  close(){
    this.dialogRef.close();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getCalculationLog();
  }

}

<ng-container>
    <div class="page-description-section">
        <div appLocalizationPopupDirective localizationCode="IcimsApiDocumentation" >
            <a href="https://developer-community.icims.com/" target="_blank">
                {{ 'IcimsApiDocumentation' | translate: {Default: "iCIMS API Documentation"} }}
            </a>
        </div>
    
        <br>
    
        <div appLocalizationPopupDirective localizationCode="IcimsMappingsPageDescription">
            {{ 'IcimsMappingsPageDescription' | translate: {Default: "This page allows you to manage the mappings for iCIMS"} }}
        </div>
    </div>

    <app-data-grid-comp
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        category="Mappings"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        [sort]="sort"
        clickableRows="true"
        [searchable]="false"
        [filter]="null"
        [filterable]="null"
        [filterCategories]="null"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitSortEvent)="sortChange($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToMapping($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
    ></app-data-grid-comp>

</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PayworksIntegrationDetails } from '@app/modules/site-settings-integrations/models/integrations.model';
import { IntegrationsService } from '@app/modules/site-settings-integrations/services/integrations.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-payworks-integration-details',
    templateUrl: './payworks-integration-details.component.html',
    styleUrls: ['./payworks-integration-details.component.scss']
})
export class PayworksIntegrationDetailsComponent implements OnInit {
    @Input() integrationId: string;
    public payworksIntegrationDetails: PayworksIntegrationDetails = null;

    // FORM
    formId: string = 'frm_adMerBDtyX29Ex';
    formData: any;
    getFormData: boolean = false;
    formValid: boolean = false;
    formPristine: boolean = true;
    formLoaded: boolean = false;

    constructor(
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private integrationService: IntegrationsService,
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    formDataEmitted(formDataEmitted) {
        this.save(formDataEmitted);
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }

        this.formValid = formValid;
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    getData() {
        this.integrationService.getPayworksIntegrationConfig(this.integrationId).subscribe(res => {
            this.payworksIntegrationDetails = res;

            this.buildFormData();
        })
    }

    buildFormData() {
        this.formData = this.payworksIntegrationDetails;
        this.formData.culture = this.payworksIntegrationDetails.culture.id;
    }

    save(formDataEmitted) {
        this.overlayService.show();

        this.integrationService.updatePayworksIntegrationConfig(this.integrationId, formDataEmitted)
            .subscribe(
                {
                    next: (v) => {
                        this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');
                    },
                    error: (e) => this.overlayService.hide(),
                    complete: () => this.overlayService.hide()
                }
            );
    }
}

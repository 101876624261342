<app-data-grid-comp
    gridId="employeeAbsences"
    [columns]="columns"
    [filterCategories]="filterCategories"
    [bindingType]="bindingType"
    [view]="view"
    [gridDataResult]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    [tableId]="db_tables.ABSENCES"
    category="tbl_Absences"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    [accessAndHistoryURL]="accessAndHistoryURL"
    [parentModelId]="employeeID"
    [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
    export="excel"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    (emitSortEvent)="sortChange($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="getEmployeeAbsence($event)"
    (emitDeleteEvent)="deleteEmployeeAbsences($event)"
    (emitFilterEvent)="filterCallback($event)"
></app-data-grid-comp>

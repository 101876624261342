import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';
import { EmployeeIntegrationDetailsService } from '../employee-integration-details/services/employee-integration-details.service';
import { EmployeeIntegrationMappingVerbose } from '../employee-integration-details/models/employee-integration-details.model';

@Component({
  selector: 'app-employee-integration-mappings',
  templateUrl: './employee-integration-mappings.component.html',
  styleUrls: ['./employee-integration-mappings.component.scss']
})
export class EmployeeIntegrationMappingsComponent implements OnInit {
  employeeID: string;

  public columns: any[] = [
    { field: "integration", subField: "name", title: "Integration", tableId: "tbl_IeIntegration" },
    { field: "externalSystemId", title: "External System Id", tableId: "tbl_IeExternalSystemId" },
  ];
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_b2vwL0d5lvAAnP'
  editFormId: string = 'frm_M6LYU6PwRuf2iX';
  // changeReasonFormId: string = 'frm_BuUOpoaVa7iQgF';
  formValid: boolean;
  private sortString: string = null;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private employeeIntegrationDetailsService: EmployeeIntegrationDetailsService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getIntegrationMappings();
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getIntegrationMappings() {
    this.isLoading = true;

    this.employeeIntegrationDetailsService.getIntegrationMappings(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  getIntegrationMapping(IntegrationMapping?: EmployeeIntegrationMappingVerbose) {
    if(IntegrationMapping === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.employeeIntegrationDetailsService.getIntegrationMapping(this.employeeID, IntegrationMapping.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(integrationMapping?: EmployeeIntegrationMappingVerbose) {
    let formData = {
      id: integrationMapping ? integrationMapping.id : null,
      integrationId: integrationMapping ? integrationMapping.integration?.id : null,
      externalSystemId: integrationMapping ? integrationMapping.externalSystemId : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('IntegrationMapping')}`,
      formId: integrationMapping ? this.editFormId : this.formId,
      // changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.employeeIntegrationDetailsService.createIntegrationMapping(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getIntegrationMappings();
        }
      );
    }
    else {
      this.employeeIntegrationDetailsService.updateIntegrationMapping(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getIntegrationMappings();
        }
      );
    }
  }

  deleteIntegrationMappings(IntegrationMappingIds: string[]) {
    const observables = IntegrationMappingIds.map(selectedItem => defer(() => this.employeeIntegrationDetailsService.deleteIntegrationMapping(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getIntegrationMappings();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getIntegrationMappings();
  }

}

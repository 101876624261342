import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { ExcelExportEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { finalize, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { EmployeeToilPolicy } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';

@Component({
  selector: 'app-toil-policy-calculation-log-dialog',
  templateUrl: './toil-policy-calculation-log-dialog.component.html',
  styleUrls: ['./toil-policy-calculation-log-dialog.component.scss']
})
export class ToilPolicyCalculationLogDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "createdOn", title: this.translate.instant("ToilCalculationLog-CreatedOn"), type: 'dateTime'},
    { field: "on", title: this.translate.instant("ToilCalculationLog-On"), type: 'date'},
    { field: "expiredOn", title: this.translate.instant("ToilCalculationLog-ExpiredOn"), type: 'date'},
    { field: "adjustment", title: this.translate.instant("ToilCalculationLog-Adjustment"), type: 'decimal'},
    { field: "runningTotal", title: this.translate.instant("ToilCalculationLog-RunningTotal"), type: 'decimal'},
    { field: "reason", title: this.translate.instant("ToilCalculationLog-Reason")},
  ];

  employeeId: string;
  // employeeTimeOffType: EmployeeTimeOffType;
  isLoading: boolean;
  timeOffCalculationLog: any[];
  pageSettings = {
    pageSizes: [5, 10, 20, 50, 100],
  };
  pageSize: number = 5;
  skip: number = 0;
  employeeToilPolicy: EmployeeToilPolicy;

  constructor(
    private timeOffInLieuService: TimeOffInLieuService,
    private sanitiseGridDataService: SanitiseGridDataService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ToilPolicyCalculationLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.employeeId = data.employeeId;
    this.employeeToilPolicy  = data.employeeToilPolicy;
  }

  ngOnInit(): void {
    this.getCalculationLog();
  }

  getCalculationLog() {
    this.isLoading = true;
    
    this.timeOffInLieuService.getEmployeeToilPolicyCalculationLog(this.employeeId, this.employeeToilPolicy?.id)
    .pipe(
      finalize(() => {
          this.isLoading = false;
      })
    )
    .subscribe(
      res => {
        this.timeOffCalculationLog = res;
      }
    );
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

  close(){
    this.dialogRef.close();
  }

}

